import { Fee } from "./fee";

export class FeePackage {
    amount: number;
    currency: string;
    name: string;
    desc: string;
    provider: string;
    _id: string;
    payments: Fee[];
    info: string
};