import { AppService } from './app.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TeamsService {

  constructor(
    private http: HttpClient,
    private app: AppService) {

  }

  get(query) {
    const params: HttpParams = new HttpParams({fromObject: query});
    return this.http.get( this.app.api() + `/teams`, {params: params} );
  }

  getById( id: string ) {
    return this.http.get( this.app.api() + `/teams/${id}` );
  }

  create( body: Object ) {
    return this.http.post( this.app.api() + `/teams`, body);
  }

  update( id: string, body: Object) {
    return this.http.put( this.app.api() + `/teams/${id}`, body);
  }

  remove( id: string ) {
    return this.http.delete( this.app.api() + `/teams/${id}`);
  }

  gps( id: string ) {
    const params: HttpParams = new HttpParams({fromObject: {
      team: id
    }});
    return this.http.get( this.app.api() + `/gps`, {params: params});
  }

  users( teamid: string ) {
    return this.http.get( this.app.api() + `/teams/${teamid}/users`);
  }

  userAdd( teamid: string, userid: string ) {
    const body = {
      user: userid
    };

    return this.http.post( this.app.api() + `/teams/${teamid}/users`, body);
  }

  userRemove( teamid: string, userid: string  ) {
    return this.http.delete( this.app.api() + `/teams/${teamid}/users/${userid}`);
  }
}
