<h1 mat-dialog-title>User add</h1>
<div mat-dialog-content *ngIf="form">
    <form [formGroup]="form" class="basic-form">
        <div fxFlex.gt-sm="45" fxFlex="45" fxFlex.gt-xs="100">
            <mat-form-field>
                <input matInput (change)="onSearch()" formControlName="email" placeholder="Enter user email">
            </mat-form-field>

        </div>
        <div class="message-box contact-box" *ngIf="user">
            <div class="message-widget contact-widget">
                <a class="contact-item">
                    <div class="user-img">
                        <img src="assets/images/users/1.jpg" alt="user" class="img-circle">
                        <span class="profile-status online pull-right"></span>
                    </div>
                    <div class="mail-contnet">
                        <h5>{{user.fname}} {{user.lname}}</h5>
                        <span class="mail-desc">{{user.email}}</span>
                    </div>
                </a>
            </div>
        </div>
        <div class="not-found" *ngIf="!user">
            {{errorMessage}}
        </div>
    </form>
</div>
<div mat-dialog-actions>
    <div class="left-buttons">
        <button mat-button (click)="onNoClick()" tabindex="1">Cancel</button>
        <button mat-button [disabled]="form.invalid" (click)="onSearch()" tabindex="2">Search</button>
        <button mat-button [disabled]="!user" (click)="onSave()" tabindex="2">Add</button>
    </div>
</div>