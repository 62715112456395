import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UsersService} from '../../../services/users.service';
import {User} from '../../../models/user';
import {Team} from '../../../models/team';
import {Vehicle} from '../../../models/vehicle';
import {TeamsService} from '../../../services/teams.service';
import {VehiclesService} from '../../../services/vehicles.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Event} from '../../../models/event';
import {EventsService} from '../../../services/events.service';
import {Product} from '../../../models/product';
import {TicketsService} from '../../../services/tickets.service';

@Component({
  selector: 'app-participant-edit-dialog',
  templateUrl: './participant-edit-dialog.component.html',
  styleUrls: ['./participant-edit-dialog.component.css']
})
export class ParticipantEditDialogComponent implements OnInit {
  event: Event;
  form: FormGroup;
  userForm: FormGroup;
  user: User;
  teams: Team[];
  vehicles: Vehicle[];
  tickets: Product[];

  userSearchError: any;
  processingError: any;
  isRequestProcessing: boolean;

  constructor(
      private usrSvc: UsersService,
      private teamSvc: TeamsService,
      private vehSvc: VehiclesService,
      private evSvc: EventsService,
      private ticketsSvc: TicketsService,
      public dialogRef: MatDialogRef<ParticipantEditDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any

  ) {}

  ngOnInit(): void {
    this.userForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    });

    this.form = new FormGroup({
      team: new FormControl('', [Validators.required]),
      vehicle: new FormControl('', [Validators.required]),
      status: new FormControl('', [Validators.required]),
      ticket: new FormControl(''),
      number: new FormControl(this.data.totalParticipants + 1)
    });

    this.event = JSON.parse( localStorage.getItem('event') );
    this.ticketsSvc.get( this.event._id ).subscribe( (tickets: Product[]) => {
        this.tickets = tickets;

        console.log(tickets);
    });
  }

  onSubmit(): void {
    const payload = {
      team: this.form.value.team,
      vehicle: this.form.value.vehicle,
      ticket: this.form.value.ticket,
      user: this.user._id,
      status: this.form.value.status,
      terms: true,
      number: this.form.value.number || 0
    };

    this.isRequestProcessing = true;

    this.evSvc.assign(this.event._id, payload).subscribe( status => {
      console.log('Assign status', status);
      this.dialogRef.close({saved: true});
    }, (err: any) => {
      console.warn('Assign Error', err);
      this.processingError = `${err.error.error}, ${err.error?.details} `;
    });

  }

  onNoClick(): void {
    this.dialogRef.close({saved: false});
  }

  onUserSearch(): void {
    this.userSearchError = null;
    this.usrSvc.search( this.userForm.value.email ).subscribe( (user: User) => {
      this.user = user;
      const query = {user: user._id};

      this.teamSvc.get(query).subscribe((teams: Team[]) => {
        this.teams = teams;
      });

      this.vehSvc.get(query).subscribe( (vehicles: Vehicle[]) => {
        this.vehicles = vehicles;
      });

    }, error => {
      this.user = null;
      this.userSearchError = error.error.error;
    });
  }
}
