import { UsersService } from './users.service';
import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class IssuesService {

  constructor(
    private http: HttpClient,
    private app: AppService) {

  }

  get(query) {
    const params: HttpParams = new HttpParams({fromObject: query});
    return this.http.get( this.app.api() + `/issues`, {params: params} );
  }
  count(query) {
    const params: HttpParams = new HttpParams({fromObject: query});
    return this.http.get( this.app.api() + `/issues/count`, {params: params} );
  }

  getById( id: string ) {
    return this.http.get( this.app.api() + `/issues/${id}` );
  }

  create( body: Object ) {
    return this.http.post( this.app.api() + `/issues`, body);
  }

  update( id: string, body: Object) {
    return this.http.put( this.app.api() + `/issues/${id}`, body);
  }

  remove( id: string ) {
    return this.http.delete( this.app.api() + `/issues/${id}`);
  }

  comments( id: string ) {
    const query = {
      issue: id
    };

    const params: HttpParams = new HttpParams({fromObject: query});
    return this.http.get( this.app.api() + `/comments`, {params: params} );
  }

  addComment( id: string, text: string, owner: string, author: string ) {
    const body = {
      owner: owner,
      author: author,
      issue: id,
      text: text
    };

    return this.http.post( this.app.api() + `/comments`, body );
  }

  removeComment( id: string ) {
    return this.http.delete( this.app.api() + `/comments/${id}` );
  }
}
