<div mat-dialog-title class="row">
    <div class="column">
        <mat-card-title>Ticket Details</mat-card-title>
        <mat-card-subtitle>Configure Ticket details</mat-card-subtitle>
    </div>
</div>

<div mat-dialog-content>
  <form [formGroup]="form" (ngSubmit)="onSubmit()" class="basic-form" fxLayout="row">
    <div class="row">
      <div class="column column-right">
        <span class="section-title">Ticket</span>

        <div fxFlex.gt-sm="100" fxFlex="100">
          <mat-form-field>
            <input matInput formControlName="name" placeholder="Name">
          </mat-form-field>
        </div>

        <div fxFlex.gt-sm="100" fxFlex="100">
          <mat-form-field>
            <input matInput formControlName="price" placeholder="Price">
          </mat-form-field>
        </div>

        <div fxFlex.gt-sm="100" fxFlex="100">
          <mat-form-field>
            <mat-select formControlName="currency" placeholder="Currency">
              <mat-option *ngFor="let c of currencies" value="{{c.cc}}">{{c.cc}} - {{c.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxFlex.gt-sm="100" fxFlex="100">
          <mat-form-field>
            <input matInput formControlName="quantity" placeholder="Quantity">
          </mat-form-field>
        </div>

        <div fxFlex.gt-sm="100" fxFlex="100">
          <mat-form-field>
            <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="5" cdkAutosizeMaxRows="10" formControlName="summary"
              placeholder="Description"></textarea>
          </mat-form-field>
        </div>

        <div fxFlex.gt-sm="100" fxFlex="100">
          <mat-form-field>
            <input matInput formControlName="stripeAccountId" placeholder="Stripe Account">
          </mat-form-field>
        </div>

        <div fxFlex.gt-sm="100" fxFlex="100">

        <mat-form-field>
          <mat-select formControlName="tax_behavior" placeholder="Taxation">
            <mat-option *ngFor="let t of taxBehavior" value="{{t}}">{{t}}</mat-option>
          </mat-select>
        </mat-form-field>
        </div>
      </div>
      <div class="column column-left"  formArrayName="items" >
        <span class="section-title">Ticket items <button mat-button (click)="onProductItemAdd()"><mat-icon>add</mat-icon></button></span>
          <div class="row" style="gap: 5px" *ngFor="let item of form.controls['items']['controls']; let c = index" [formGroupName]="c">
            <div fxFlex="40">
              <mat-form-field>
                <input matInput formControlName="name" placeholder="Name">
              </mat-form-field>
            </div>
            <div fxFlex="30">
              <mat-form-field>
                <input matInput formControlName="amount" placeholder="Amount">
              </mat-form-field>
            </div>
            <div fxFlex="30">
              <mat-form-field>
                <mat-select formControlName="tax_code" placeholder="Taxation">
                  <mat-option *ngFor="let tax of taxes" value="{{tax.code}}">{{tax.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxFlex="10">
                  <button mat-button (click)="onProductItemRemove(c)"><mat-icon>delete</mat-icon></button>
            </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div mat-dialog-actions>
  <button *ngIf="data.name" mat-button color="warn" (click)="onRemove()" tabindex="-1">Delete</button>
  <button mat-button (click)="onNoClick()" tabindex="-1">Cancel</button>
  <button mat-button [disabled]="form.invalid" (click)="onSave()" tabindex="2">Save</button>
</div>
