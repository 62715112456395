import {Component, Inject, OnInit} from '@angular/core';
import {AppService} from '../../../services/app.service';
import {EventsService} from '../../../services/events.service';
import {PaymentsService} from '../../../services/payments.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Transaction} from '../../../models/transactions';
import {Event} from '../../../models/event';
import {TransactionsService} from '../../../services/transactions.service';

@Component({
  selector: 'app-transaction-details-dialog',
  templateUrl: './transaction-details-dialog.component.html',
  styleUrls: ['./transaction-details-dialog.component.css']
})
export class TransactionDetailsDialogComponent implements OnInit {
  form: FormGroup;
  viewed: boolean;
  event: Event;

  constructor(
      private app: AppService,
      private evSvc: EventsService,
      private paySvc: PaymentsService,
      private trxSvc: TransactionsService,
      public dialogRef: MatDialogRef<TransactionDetailsDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: Transaction
      ) {
    this.viewed = false;
  }

  ngOnInit(): void {
    this.event = JSON.parse( localStorage.getItem('event') );
    this.form = new FormGroup({
      status: new FormControl( this.data.status, [Validators.required]),
      viewed: new FormControl( this.data.metadata.viewed || false),
      invoice: new FormControl( this.data.metadata.invoice_vatid),
      comment: new FormControl( this.data.metadata.internal_comment),
    });
  }

  onAttachmentDownload(filename: string) {
    this.paySvc.attachment( this.data._id, this.event._id, filename ).subscribe( (data: any) => {
      const link = document.createElement('a');
      link.setAttribute('download', '');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', data.url);
      link.click();
      link.remove();
    }, err => {
      console.error( err );
    });
  }

  async onSubmit() {
    this.data.status = this.form.value.status;
    this.data.metadata.invoice = this.form.value.invoice?.trim().length > 0 ? true : false,
    this.data.metadata.invoice_vatid = this.form.value?.invoice;
    this.data.metadata.viewed = this.form.value.viewed;
    this.data.metadata.internal_comment = this.form.value.comment;

    const body = {
      ...this.data
    };


    try {
      const update = await this.trxSvc.update( this.data._id, this.event._id, body).toPromise<any>();
      this.dialogRef.close( this.data );
    } catch (err) {
      console.error( err );
    }
  }
}
