<h1 mat-dialog-title>Payment</h1>
<div mat-dialog-content>
    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="basic-form">
        <div fxLayout="row wrap">
            <!-- column -->
            <div fxFlex.gt-sm="100" fxFlex="100">
                <mat-select-autocomplete formControlName="team"
                                         [placeholder]="'Team'"
                                         [options]="teams"
                                         [multiple]='false'
                                         ngDefaultControl
                                         [formControl]="form.controls['team']">
                </mat-select-autocomplete>
            </div>
            <div fxFlex.gt-sm="100" fxFlex="100">
                <mat-form-field>
                    <mat-select formControlName="type" placeholder="Type">
                        <mat-option value="donation">Donation</mat-option>
                        <mat-option value="commercial">Commercial</mat-option>
                        <mat-option value="fee">Fee</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex.gt-sm="100" fxFlex="100">
                <mat-form-field>
                    <input matInput formControlName="amount" placeholder="Amount">
                </mat-form-field>
            </div>
            <div fxFlex.gt-sm="100" fxFlex="100">
                <mat-form-field>
                    <mat-select formControlName="currency" placeholder="Currency">
                        <mat-option *ngFor="let c of currencies" value="{{c.cc}}">{{c.cc}} - {{c.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex.gt-sm="100" fxFlex="100">
                <mat-form-field>
                    <mat-select formControlName="status" placeholder="Status">
                        <mat-option value="pending">Pending</mat-option>
                        <mat-option value="settled">Settled</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex.gt-sm="100" fxFlex="100">
                <mat-form-field>
                    <input matInput formControlName="comment" placeholder="Comment">
                </mat-form-field>
            </div>
        </div>
    </form>
</div>
<div mat-dialog-actions>
    <button *ngIf="data.name" mat-button color="warn" (click)="onRemove()" tabindex="-1">Delete</button>
    <button mat-button (click)="onNoClick()" tabindex="-1">Cancel</button>
    <button mat-button [disabled]="form.invalid" (click)="onSave()" tabindex="2">Save</button>
</div>
