import { CampsService } from './../../../services/camps.service';
import { CampsComponent } from './../../event/camps/camps.component';
import { Component, OnInit, Inject, ViewChild  } from '@angular/core';
import { Camping } from '../../../models/camp';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-camping-dialog',
  templateUrl: './camping-dialog.component.html',
  styleUrls: ['./camping-dialog.component.scss']
})
export class CampingDialogComponent implements OnInit {
  campings: Camping[];
  selectedCamp: Camping;
  openSuggest: Boolean

  constructor(
    private campSvc: CampsService,
    public dialogRef: MatDialogRef<CampingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.loadCampings();
    this.openSuggest = true
  }

  loadCampings() {

    const query = {
      limit: 0,
      sort: 'country:1'
    };

    this.campSvc.get( query ).subscribe( (camps: Camping[] ) => {
      this.campings = camps;
    }, err => {
      console.error( err );
    });
  }
  applyFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    //this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onNoClick(): void {
    this.dialogRef.close();
    this.dialogRef.afterClosed
  }

  onSelect( camp: Camping ) {
    this.selectedCamp = camp;
  }

}
