<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="main-container">
  <mat-sidenav-container [style.marginTop.px]="mobileQuery.matches ? 0 : 0">
    <mat-sidenav-content class="page-wrapper">
      <div class="page-content">
        <router-outlet>
          <app-spinner></app-spinner>
        </router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>