import { Camping } from './../../../models/camp';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { CampsService } from '../../../services/camps.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppService } from '../../../services/app.service';

@Component({
  selector: 'app-camping-suggest-dialog',
  templateUrl: './camping-suggest-dialog.component.html',
  styleUrls: ['./camping-suggest-dialog.component.scss']
})
export class CampingSuggestDialogComponent implements OnInit {

  form: FormGroup;
  countries: any[];
  mapMarker: any;

  constructor(
    private app: AppService,
    private campSvc: CampsService,
    public dialogRef: MatDialogRef<CampingSuggestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Camping
  ) { }

  ngOnInit(): void {
    this.onFormInit();

    this.app.countries().subscribe( (countries: any[]) => {
      this.countries = countries;
    });

    if (this.data) {
      this.mapMarker = {
        lat: this.data.lat,
        lng: this.data.lng
      };
    }
  }

  onMapClick = (data) => {
    this.form.get('lat').setValue(data.lat);
    this.form.get('lng').setValue(data.lng);
  }

  onFormInit() {
    let camp = new Camping();

    if (this.data) {
      camp = this.data;
      camp.lat = this.data.gps.coordinates[1];
      camp.lng = this.data.gps.coordinates[0];
    }

    this.form = new FormGroup({
      name: new FormControl( camp.name, [Validators.required]),
      desc: new FormControl( camp.desc, [Validators.required]),
      city: new FormControl( camp.city, [Validators.required]),
      lat: new FormControl( camp.lat, [Validators.required] ),
      lng: new FormControl( camp.lng , [Validators.required]),
      street: new FormControl( camp.street, [Validators.required]),
      area: new FormControl( camp.area , [Validators.required]),
      country: new FormControl( camp.country, [Validators.required]),
      code: new FormControl( camp.code, [Validators.required]),
    });
  }

  onSubmit() {

    if (this.form.invalid) {
      return false;
    }

    if (!this.data) {
      this.campSvc.add( this.form.value ).subscribe( camp => {
        console.log('Camp Added', camp);
        this.dialogRef.close( camp );
      }, err => {
        console.error( err );
        this.dialogRef.close(false);
      });
    } else {
      this.campSvc.update( this.data._id, this.form.value ).subscribe( camp => {
        console.log('Camp Updated', camp);
        this.dialogRef.close( camp );
      }, err => {
        console.error( err );
        this.dialogRef.close(false);
      });
    }
  }

  onDialogClose() {
    this.dialogRef.close(false);
  }

}
