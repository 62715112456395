export class Fundraise {
    stripeAccount: string;
    amount: number;
    currency: string;
    name: string;
    desc: string;

    createdAt: Date;
    updatedAt: Date;
    enabled: boolean;

    collect: [{
        vehicle: string,
        amount: number
    }];

    _id: string;
}
