import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class PostsService {

  constructor(
    private http: HttpClient,
    private app: AppService) {

  }

  get( query: any ) {
    const params: HttpParams = new HttpParams({fromObject: query});
    return this.http.get( this.app.api() + `/posts`, {params: params});
  }

  getById( id: string ) {
    return this.http.get( this.app.api() + `/posts/${id}` );
  }

  create( body: Object ) {
    return this.http.post( this.app.api() + `/posts`, body);
  }

  update( id: string, body: Object) {
    return this.http.put( this.app.api() + `/posts/${id}`, body);
  }

  remove( id: string ) {
    return this.http.delete( this.app.api() + `/posts/${id}`);
  }

  like( id: string, unline: boolean = false ) {

  }

  unlike( id: string ) {
    return this.like( id, true);
  }

  comments( id: string ) {
    const query = {
      post: id
    };

    const params: HttpParams = new HttpParams({fromObject: query});
    return this.http.get( this.app.api() + `/comments`, {params: params} );
  }

  addComment( post: string, text: string, owner: string, author: string ) {
    const body = {
      owner: owner,
      author: author,
      post: post,
      text: text
    };

    return this.http.post( this.app.api() + `/comments`, body );
  }

  removeComment( id: string ) {
    return this.http.delete( this.app.api() + `/comments/${id}` );
  }
}
