<h1 mat-dialog-title>Fundraise definition</h1>
<div mat-dialog-content>
    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="basic-form">
        <div fxLayout="row wrap">
            <!-- column -->
            <div fxFlex.gt-sm="100" fxFlex="100">
                <mat-form-field>
                    <input matInput formControlName="name" placeholder="Title of payment">
                </mat-form-field>
            </div>
            <div fxFlex.gt-sm="100" fxFlex="100">
                <mat-form-field>
                    <input matInput formControlName="stripeAccount" placeholder="Stripe Account Id">
                </mat-form-field>
            </div>
            <div fxFlex.gt-sm="100" fxFlex="100">
                <mat-form-field>
                    <mat-select formControlName="currency" placeholder="Currency">
                        <mat-option *ngFor="let c of currencies" value="{{c.cc}}">{{c.cc}} - {{c.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div fxFlex.gt-sm="100" fxFlex="100">
                <mat-form-field>
                    <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="5" cdkAutosizeMaxRows="10" formControlName="desc" placeholder="Description"></textarea>
                </mat-form-field>
            </div>
            <div fxFlex.gt-sm="100" fxFlex="100">
                <mat-form-field>
                    <input matInput formControlName="amount" placeholder="Minimal amount to raise">
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" formArrayName="collect">
            <div>
                <b>Define amount based on Vehicle type</b>
                <button mat-icon-button color="primary" type="button" (click)="onAddCollectRule()">
                    <mat-icon>add_circle_outline</mat-icon>
                </button>
            </div>

            <div *ngFor="let item of form.controls['collect']['controls']; let c = index" [formGroupName]="c" >
                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
                    <mat-form-field fxFlex="10">
                        <mat-select formControlName="vehicle" placeholder="Vehicle">
                            <mat-option *ngFor="let v of vehicles" value="{{v}}">{{v}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex="10">
                        <input matInput formControlName="amount" placeholder="Amount">
                    </mat-form-field>
                    <button mat-icon-button color="primary" type="button" (click)="onRemoveCollectRule(c)">
                        <mat-icon>remove_circle_outline</mat-icon>
                    </button>
                    <button mat-icon-button color="primary" type="button" (click)="onAddCollectRule()">
                        <mat-icon>add_circle_outline</mat-icon>
                    </button>
                </div>
                <hr />
            </div>
        </div>
    </form>
</div>
<div mat-dialog-actions>
    <button *ngIf="data.name" mat-button color="warn" (click)="onRemove()" tabindex="-1">Delete</button>
    <button mat-button (click)="onNoClick()" tabindex="-1">Cancel</button>
    <button mat-button [disabled]="form.invalid" (click)="onSave()" tabindex="2">Save</button>
</div>
