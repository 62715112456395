import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Fee } from '../../../models/fee';
import { AppService } from '../../../services/app.service';

@Component({
  selector: 'app-fee-dialog',
  templateUrl: './fee-dialog.component.html',
  styleUrls: ['./fee-dialog.component.css']
})
export class FeeDialogComponent implements OnInit {

  form: FormGroup;
  currencies: any[];
  create: boolean = false;

  constructor(
    private app: AppService,
    public dialogRef: MatDialogRef<FeeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {

    this.currencies = [];

    this.app.currencies().subscribe( (data: any[]) => {
      this.currencies = data;
    }, err => {
      console.error(err);
    });

    if (!this.data) {
      this.data = new Fee();
      this.create = true;
    }

    this.form = new FormGroup({
      name: new FormControl( this.data.name, [Validators.required]),
      title: new FormControl( this.data.title, [Validators.required]),
      amount: new FormControl( this.data.amount, [Validators.pattern('^[+-]?\\d+(\\.\\d+)?$'), Validators.required] ),
      type: new FormControl( this.data.type, [Validators.required]),
      ban: new FormControl( this.data.ban),
      desc: new FormControl( this.data.desc, [Validators.required]),
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onRemove(): void {
    this.dialogRef.close({action: 'delete'});
  }

  onSave() {
    if(this.create) {
      this.dialogRef.close({action: 'create', fee: this.form.value});
    } else {
      this.dialogRef.close({action: 'update', fee: this.form.value});
    }
  }

  onSubmit() {

  }
}
