type PaymentModes = 'payment' | 'setup' | 'subscription';
type PaymentType = 'pay' | 'book' | 'donate';
type PaymentStatus = 'unpaid' | 'pending' | 'paid';

export class Transaction {
    _id: string;
    user: string;
    order: any;
    session: string;
    mode: PaymentModes;
    type: PaymentType;
    status: PaymentStatus;
    metadata: any;
    createdAt: string;
    updatedAt: string;
    amount: number;
    currency: string;
}
