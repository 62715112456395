
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { CheckpointsService } from '../../../services/checkpoints.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Checkpoint } from '../../../models/checkpoint';
import { MapSelectorComponent } from '../map-selector/map-selector.component';
import { Event } from '../../../models/event';
@Component({
  selector: 'app-checkpoint-dialog',
  templateUrl: './checkpoint-dialog.component.html',
  styleUrls: ['./checkpoint-dialog.component.css']
})
export class CheckpointDialogComponent implements OnInit {
  form: FormGroup;
  mapMarker: any;
  ckp:Checkpoint;
  event: Event;
  body:any;
  constructor(
    private ckpSvc: CheckpointsService,
    public dialogRef: MatDialogRef<CheckpointDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Checkpoint
  ) { }

  ngOnInit(): void {
    this.onFormInit();
    this.event = JSON.parse( localStorage.getItem('event') );
  }
  onMapClick = (data) => {
    this.form.get('lat').setValue(data.lat);
    this.form.get('lng').setValue(data.lng);
  }

  onFormInit() {
    let ckp = new Checkpoint();
    this.form = new FormGroup({
      name: new FormControl("", [Validators.required]),
      desc: new FormControl("",[Validators.required]),
      lat: new FormControl("",[Validators.required]),
      lng: new FormControl("",[Validators.required]),
      order: new FormControl("", [Validators.required]),
      points: new FormControl("",[Validators.required]),
      });
      if(!this.data){}else{
        console.log(this.data)
        this.form.get('name').setValue(this.data.name),
        this.form.get('desc').setValue(this.data.desc),
        this.form.get('lat').setValue(this.data.gps.coordinates[1]) , 
        this.form.get('lng').setValue(this.data.gps.coordinates[0]),
        this.form.get('order').setValue(this.data.order)
        this.form.get('points').setValue(this.data.points)
      }
  }

  onSubmit() {

    if (this.form.invalid) {
      return false;
    }
    
    if (!this.data) {      
      this.body= {
        name: this.form.get('name').value,
        desc: this.form.get('desc').value,
        gps: {
            latitude: this.form.get('lat').value , 
            longitude: this.form.get('lng').value
        },
        order: this.form.get('order').value,
        points: this.form.get('points').value
     }
      this.ckpSvc.add( this.event._id, this.body ).subscribe( ckp => {
        console.log('Checkpoint Added', ckp);
        this.dialogRef.close( ckp );
      }, err => {
        console.error( err );
        this.dialogRef.close(false);
      });
    } else {
      this.body= {
        name: this.form.get('name').value,
        desc: this.form.get('desc').value,
        gps: {
            latitude: this.form.get('lat').value , 
            longitude: this.form.get('lng').value
        },
        order: this.form.get('order').value,
        points: this.form.get('points').value
     }

      this.ckpSvc.update( this.event._id, this.data._id, this.body ).subscribe( ckp => {
        console.log('Checkpoint Updated', ckp);
        this.dialogRef.close( ckp );
      }, err => {
        console.error( err );
        this.dialogRef.close(false);
      });
    }

    
  }

  onDialogClose() {
    this.dialogRef.close(false);
  }



}
