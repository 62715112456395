<h1 mat-dialog-title>
    Participant: {{ data.team.name }} ({{data.team?.uid}})<br/>
    <small>Join: {{ data.createdAt | date:'mediumDate'}}</small>
</h1>

<div mat-dialog-content *ngIf="form">
    <div fxLayout="row">
        <div fxFlex.gt-sm="45" fxFlex="45" fxFlex.gt-xs="100">
        </div>
        <div fxFlex.gt-sm="45" fxFlex="45" fxFlex.gt-xs="100">
            <form [formGroup]="form" (ngSubmit)="onSave()" class="basic-form">
                <mat-form-field>
                    <mat-label>Participation Status</mat-label>
                    <mat-select placeholder="" formControlName="status">
                        <mat-option value="approved">Approved - can take part of event</mat-option>
                        <mat-option value="pending">Pending - waiting for approval</mat-option>
                        <mat-option value="rejected">Rejected - can't take part of event</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Start Number</mat-label>
                    <input matInput formControlName="number" placeholder="Start Number">
                </mat-form-field>
            </form>
        </div>
    </div>

    <mat-tab-group>
        <mat-tab label="Request">
            <mal-list class="section">
                <b class="section-title">Contacts</b>
                <mat-list-item *ngFor="let usr of data.team.users">
                    {{usr.user?.fname}} {{usr.user?.lname}}
                    <a href="mailto:{{usr.user?.email}}">{{usr.user?.email}}</a>
                </mat-list-item>
            </mal-list>

            <mat-list class="section">
                <b class="section-title">Team</b>
                <mat-list-item><b>Team name</b>: {{data.team.name}}</mat-list-item>
                <mat-list-item><b>Description</b>: {{data.team?.desc}}</mat-list-item>
                <mat-list-item><b>City</b>: {{data.team?.city}}</mat-list-item>
                <mat-list-item><b>Country</b>: {{data.team?.country}}</mat-list-item>
            </mat-list>

            <mat-list class="section">
                <b class="section-title">Vehicle</b>
                <mat-list-item><b>Vehicle</b>: {{data.vehicle?.mark}} {{data.vehicle?.model}}</mat-list-item>
                <mat-list-item><b>Type</b>: {{data.vehicle?.type}}</mat-list-item>
                <mat-list-item><b>Fuel</b>: {{data.vehicle?.fuel}}</mat-list-item>
            </mat-list>
        </mat-tab>

        <mat-tab label="Transactions">
            <table cdk-table [dataSource]="transactions" class="mat-elevation-z0">

                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef> Date </th>
                    <td mat-cell *matCellDef="let element" class="td-name">
                        {{element.createdAt | date:'mediumDate'}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="order">
                    <th mat-header-cell *matHeaderCellDef> Order </th>
                    <td mat-cell *matCellDef="let element">{{element.order?.number}}</td>
                </ng-container>

                <ng-container matColumnDef="ticket">
                    <th mat-header-cell *matHeaderCellDef> Ticket </th>
                    <td mat-cell *matCellDef="let element">{{element.metadata?.ticket}}</td>
                </ng-container>

                <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef> Amount </th>
                    <td mat-cell *matCellDef="let element">{{element.metadata?.amount/100}} {{element.metadata?.currency}}</td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef> Status </th>
                    <td mat-cell *matCellDef="let element">{{element.status }}</td>
                </ng-container>

                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef> Type </th>
                    <td mat-cell *matCellDef="let element">{{element.type}}</td>
                </ng-container>

                <ng-container matColumnDef="viewed">
                    <th mat-header-cell *matHeaderCellDef> Viewed </th>
                    <td mat-cell *matCellDef="let element">{{element.metadata.viewed ? 'Y' : 'N'}}</td>
                </ng-container>

                <ng-container matColumnDef="internal_comment">
                    <th mat-header-cell *matHeaderCellDef> Internal Comment </th>
                    <td mat-cell *matCellDef="let element">{{element.metadata.internal_comment}}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns" class="row-bordered"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onTransactionView(row)" class="tr-hover"></tr>
            </table>
        </mat-tab>
        <mat-tab label="Vehicle">
            <mat-list>
                <mat-list-item><b>Gearbox</b>: {{data.vehicle?.gearbox}}</mat-list-item>
                <mat-list-item><b>Type</b>: {{data.vehicle?.type}}</mat-list-item>
                <mat-list-item><b>Fuel</b>: {{data.vehicle?.fuel}}</mat-list-item>
                <mat-list-item><b>Mark</b>: {{data.vehicle?.mark}}</mat-list-item>
                <mat-list-item><b>Model</b>: {{data.vehicle?.model}}</mat-list-item>
                <mat-list-item><b>Year</b>: {{data.vehicle?.year}}</mat-list-item>
                <mat-list-item><b>Color</b>: {{data.vehicle?.color}}</mat-list-item>
                <mat-list-item><b>Country</b>: {{data.vehicle?.country}}</mat-list-item>
                <mat-list-item><b>Details</b>: {{data.vehicle?.details}}</mat-list-item>
                <mat-list-item><b>Engine</b>: {{data.vehicle?.engine?.power}}</mat-list-item>
                <mat-list-item><b>Plate</b>: {{data.vehicle?.plate}}</mat-list-item>
                <mat-list-item><img src="{{imageUrl }}{{data.vehicle?.image}}"></mat-list-item>
            </mat-list>
        </mat-tab>
    </mat-tab-group>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()" tabindex="1">Cancel</button>
    <button mat-button [disabled]="form.invalid" (click)="onSave()" tabindex="2">Save</button>
</div>
