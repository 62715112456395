<h1 mat-dialog-title>Create Event</h1>
<div mat-dialog-content>
    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="basic-form">
        <div fxLayout="row wrap">
            <!-- column -->
            <div fxFlex.gt-sm="100" fxFlex="100">
                <mat-form-field>
                    <input matInput formControlName="name" placeholder="Title of your Event">
                </mat-form-field>
            </div>
        </div>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()" tabindex="-1">Cancel</button>
    <button mat-button [disabled]="form.invalid" (click)="onSubmit()" tabindex="2">Save</button>
</div>