import { Event } from './event';
import { Team } from './team';
import { User } from './user';

export class Payment {
    amount: number;
    createdAt: Date;
    currency: string;
    invoice: string;
    comment: string;
    viewed: boolean;
    event: Event;
    order: string;
    participant: string;
    provider: string;
    status: string;
    team: Team;
    transaction: string;
    updatedAt: Date;
    user: User;
    _id: string;
    files: [];
    code: string;
    type: string;
}