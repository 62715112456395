import { Routes } from '@angular/router';
import { FullComponent } from './layouts/full/full.component';
import { SimpleComponent } from './layouts/simple/simple.component';
import { AuthGuard } from './guards/auth.guard';

export const AppRoutes: Routes = [
  {
    path: 'auth',
    component: SimpleComponent,
    children: [      
      {
        path: '',
        loadChildren:
          () => import('./auth/auth.module').then(m => m.AuthModule)
      },
    ]
  },{
    path: '',
    canActivate: [AuthGuard],
    component: FullComponent,
    children: [{
        path: '',
        canActivate: [AuthGuard],
        loadChildren:
          () => import('./event/event.module').then(m => m.EventModule)
      }
    ]
  },
  { path: '**', redirectTo: '/dashboard' }
];
