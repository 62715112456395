<h1 mat-dialog-title>Payment definition</h1>
<div mat-dialog-content>
  <form [formGroup]="form" (ngSubmit)="onSubmit()" class="basic-form">
    <div fxLayout="row wrap">
      <!-- column -->
      <div fxFlex.gt-sm="100" fxFlex="100">
        <mat-form-field>
          <input matInput formControlName="name" placeholder="Company / Person name">
        </mat-form-field>
      </div>
      <div fxFlex.gt-sm="100" fxFlex="100">
        <mat-form-field>
          <mat-select formControlName="type" placeholder="Type">
            <mat-option value="donation">Donation</mat-option>
            <mat-option value="commerce">Commerce</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex.gt-sm="100" fxFlex="100" *ngIf="form.value.provider != 'manual'">
        <mat-form-field>
          <input matInput formControlName="ban" placeholder="Bank Account Number">
        </mat-form-field>
      </div>
      <div fxFlex.gt-sm="100" fxFlex="100">
        <mat-form-field>
          <input matInput formControlName="title" placeholder="Title of payment">
        </mat-form-field>
      </div>
      <div fxFlex.gt-sm="100" fxFlex="100">
        <mat-form-field>
          <input matInput formControlName="amount" placeholder="Amount">
        </mat-form-field>
      </div>

      <div fxFlex.gt-sm="100" fxFlex="100">
        <mat-form-field>
          <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="5" cdkAutosizeMaxRows="10" formControlName="desc"
            placeholder="Description"></textarea>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button *ngIf="data.name" mat-button color="warn" (click)="onRemove()" tabindex="-1">Delete</button>
  <button mat-button (click)="onNoClick()" tabindex="-1">Cancel</button>
  <button mat-button [disabled]="form.invalid" (click)="onSave()" tabindex="2">Save</button>
</div>