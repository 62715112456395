import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { Payment } from '../models/payment';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  constructor(
    private http: HttpClient,
    private app: AppService) {
  }

  getTemplates() {
    return this.http.get( this.app.api() + `/events/templates`);
  }

  get( query: any ) {
    const params: HttpParams = new HttpParams({fromObject: query});
    return this.http.get( this.app.api() + `/events`, {params: params});
  }

  getById( id: string ) {
    return this.http.get( this.app.api() + `/events/${id}` );
  }

  create( body: Object ) {
    return this.http.post( this.app.api() + `/events`, body);
  }

  update( id: string, body: Object) {
    return this.http.put( this.app.api() + `/events/${id}`, body);
  }

  remove( id: string ) {
    return this.http.delete( this.app.api() + `/events/${id}`);
  }

  assign(id: string, body: any) {
    return this.http.post( this.app.api() + `/events/${id}/participants/assign`, body);
  }

  join( id: string, body: any ) {
    return this.http.post( this.app.api() + `/events/${id}/participants`, body);
  }

  leave(id: string, participant: any ) {
    return this.http.delete( this.app.api() + `/events/${id}/participants/${participant}`);
  }

  stats( id: string ) {
    return this.http.get( this.app.api() + `/events/${id}/stats` );
  }

  media( id: string, query: any ) {
    const params: HttpParams = new HttpParams({fromObject: query});
    return this.http.get( this.app.api() + `/events/${id}/media`, {params: params});
  }

  locations( id: string ) {
    const params: HttpParams = new HttpParams({fromObject: {event: id}});
    return this.http.get( this.app.api() + `/gps`, {params: params});
  }

  camps( id: string ) {
    return this.http.get( this.app.api() + `/events/${id}/camps`);
  }

  campAdd(idevent: string, idcamp: string ) {
    const body = {
      camp: idcamp,
      desc: '',
      area: ''
    };

    return this.http.post( this.app.api() + `/events/${idevent}/camps`, body);
  }

  campUpdate(idevent: string, ideventcamp: string, area: string, desc: string) {
    const body = {
      desc: desc,
      area: area
    };

    return this.http.put( this.app.api() + `/events/${idevent}/camps/${ideventcamp}`, body);
  }

  campRemove(idevent: string, ideventcamp: string) {
    return this.http.delete( this.app.api() + `/events/${idevent}/camps/${ideventcamp}`);
  }

  notifs( id: string ) {
    return this.http.get( this.app.api() + `/events/${id}/notifs`);
  }

  notifAdd( id: string, text: string ) {
    const body = {
      text: text
    };

    return this.http.post( this.app.api() + `/events/${id}/notifs`, body);
  }

  notifRemove( idevent: string, idnotif: string ) {
    return this.http.delete( this.app.api() + `/events/${idevent}/notifs/${idnotif}`);
  }

  users( eventid: string ) {
    return this.http.get( this.app.api() + `/events/${eventid}/users`);
  }

  userAdd( eventid: string, userid: string ) {
    const body = {
      user: userid
    };

    return this.http.post( this.app.api() + `/events/${eventid}/users`, body);
  }

  userRemove( eventid: string, userid: string  ) {
    return this.http.delete( this.app.api() + `/events/${eventid}/users/${userid}`);
  }

  participants( id: string, status: string, query: any = null) {

    if ( ! query ) {
      query = {
        status: status
      };

    } else {
      query.status = status;
    }

    const params: HttpParams = new HttpParams({fromObject:  query });

    return this.http.get( this.app.api() + `/events/${id}/participants`, {params: params});
  }

  countParticipants(eventid, status) {
    const query = {
      status: status
    };

    const params: HttpParams = new HttpParams({fromObject:  query });
    if (status !== null){
      return this.http.get( this.app.api() + `/events/${eventid}/participants/count`, {params: params});
    }

    return this.http.get( this.app.api() + `/events/${eventid}/participants/count`);
  }

  setJoinStatus( idevent: any, participant: string, status: string ) {
    return this.http.put( this.app.api() + `/events/${idevent}/participants/${participant}/${status}`, {});
  }

  setStartNumber( idevent: any, participant: string, number: number ) {
    const body = {
      number: number
    };

    return this.http.put( this.app.api() + `/events/${idevent}/participants/${participant}/start-number`, body);
  }

  updateParticipant(idevent: any, participant: string, body: any ) {
    return this.http.put(this.app.api() + `/events/${idevent}/participants/${participant}`, body);
  }

  payments(idevent: string, idteam: string ) {

    const query: any = {};

    if (idteam) {
      query.team = idteam;
    }

    const params: HttpParams = new HttpParams({fromObject:  query });
    return this.http.get( this.app.api() + `/payments/events/${idevent}`, {params: params});
  }

  paymentAdd(idevent: string, payment: any) {
    payment.event = idevent;
    return this.http.post( this.app.api() + `/payments/events/${idevent}`, payment);
  }

  paymentRemove(idevent: string, paymentid: string) {
    return this.http.delete( this.app.api() + `/payments/events/${idevent}/${paymentid}`);
  }

  fundraiseTotalAmount(idevent: string) {
    return this.http.get( this.app.api() + `/events/${idevent}/fundraise/total`);
  }

  fundraiseParticipantsTotalAmount(idevent: string) {
    return this.http.get( this.app.api() + `/events/${idevent}/fundraise/payments`);
  }
}
