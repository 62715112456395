import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Contact } from '../../../models/contact';

@Component({
  selector: 'app-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.scss']
})
export class ContactDialogComponent implements OnInit {
  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ContactDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {

    if (!this.data) {
      this.data = {
        fname: '', lname: '', email: '', phone: ''
      }
    }

    this.form = new FormGroup({
      fname: new FormControl( this.data.fname, [Validators.required]),
      lname: new FormControl( this.data.lname),
      email: new FormControl( this.data.email, [Validators.required, Validators.email]),
      phone: new FormControl( this.data.phone),
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onRemove(): void {
    this.dialogRef.close({action: 'delete'});
  }

  onSubmit() {

  }
}
