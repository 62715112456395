<h1 mat-dialog-title>Notification</h1>
<div mat-dialog-content *ngIf="form">
    <form [formGroup]="form" class="basic-form">
        <div fxFlex.gt-sm="45" fxFlex="45" fxFlex.gt-xs="100">
            <mat-form-field>
                <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="10" cdkAutosizeMaxRows="20" formControlName="text" placeholder="Message"></textarea>
            </mat-form-field>
        </div>
    </form>
</div>
<div mat-dialog-actions>
    <div class="left-buttons">
        <button mat-button (click)="onNoClick()" tabindex="1">Cancel</button>
        <button mat-button [disabled]="form.invalid" (click)="onSave()" tabindex="2">Save</button>
    </div>
</div>