<h1 mat-dialog-title>Contact</h1>
<div mat-dialog-content>   
    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="basic-form">
        <div fxLayout="row wrap">
            <!-- column -->
            <div fxFlex.gt-sm="100" fxFlex="100">
              <mat-form-field>
                <input matInput formControlName="fname" placeholder="First name">
              </mat-form-field>
            </div>
            <div fxFlex.gt-sm="100" fxFlex="100">
              <mat-form-field>
                <input matInput formControlName="lname" placeholder="Last name">
              </mat-form-field>
            </div>
            <div fxFlex.gt-sm="100" fxFlex="100">
              <mat-form-field>
                <input matInput formControlName="email" placeholder="Email address">
              </mat-form-field>
            </div>
            <div fxFlex.gt-sm="100" fxFlex="100">
              <mat-form-field>
                <input matInput formControlName="phone" placeholder="Phone number">
              </mat-form-field>
            </div>
        </div>
    </form>
</div> 
<div mat-dialog-actions>
      <button *ngIf="data.fname" mat-button color="warn" (click)="onRemove()" tabindex="-1">Delete</button>
      <button mat-button (click)="onNoClick()" tabindex="-1">Cancel</button>
      <button mat-button [disabled]="form.invalid" [mat-dialog-close]="form.value" tabindex="2">Save</button>
</div>