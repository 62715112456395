export class Fee {
    amount: number;
    currency: string;
    ban: string;
    name: string;
    title: string;
    desc: string;
    type: string;
    provider: string;
    _id: string;
};