import { EventsService } from './../../../services/events.service';
import { Component } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { MatDialog } from '@angular/material';
import { EventSelectorDialogComponent } from '../event-selector-dialog/event-selector-dialog.component';

@Component({
  selector: 'app-event-selector',
  templateUrl: './event-selector.component.html',
  styleUrls: []
})
export class EventSelectorComponent {
  name: string;

  constructor(
    public dialog: MatDialog,
    public auth: AuthService,
    private evSvc: EventsService) {
      this.name = 'Click me to select event'
  }

  ngOnInit(): void {
    if (localStorage.getItem('event') ) {
      try {
        let event = JSON.parse( localStorage.getItem('event') );
        this.name = event.name;
      } catch( err ) {
        console.error(err);
      }
    }
  }

  onSelect() {
    const dialogRef = this.dialog.open(EventSelectorDialogComponent, {
      width: '700px'
    });

    dialogRef.afterClosed().subscribe( event => {
      console.log('The dialog was closed');

      if (event) {
        this.loadEventDetails( event );
      }
    });
  }

  loadEventDetails( event ) {
    this.evSvc.getById( event._id ).subscribe( (ev: any)  => {

      this.name = ev.name;
      localStorage.setItem('event', JSON.stringify(ev) );

      // TODO: Change this to better UX experiance
      // window.location.reload();
    });
  }

}
