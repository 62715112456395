import { Component, OnInit, Input } from '@angular/core';
import { LeafletUtil } from '@asymmetrik/ngx-leaflet';
import { Map, latLng, tileLayer, Layer, LatLngBounds, marker, icon, Handler, featureGroup, bounds, Point, Bounds, Marker, LatLng, latLngBounds} from 'leaflet';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {
  marker: Marker;
  corners:LatLng[];
  mapOptions: any;
  mapCenter: LatLng;
  loc: any;
  map: Map;

  constructor() {
      this.markers = [];
      this.corners = [];
  }

  @Input() set layer (value: Marker) {
    if (!value) {
      return;
    }
    this.marker = value;
    
  }

  @Input() markers: Marker[];
  @Input() set center(val: LatLng) {
    if (!val) {
      return;
    }
    this.mapCenter = val;
  }

  ngOnInit() {
    this.loc = {
      lat: 50.266769,
      lng: 19.025348
    };

    this.mapOptions = {
      layers: [
        tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          maxZoom: 20,
          attribution: 'RallyGo',
        })
      ],
      zoom: 8,
      center: latLng(this.mapCenter.lat, this.mapCenter.lng)
    };
  }

  onMapReady( map: Map ) {
    map.invalidateSize(true);
    this.map = map;
    this.map.setZoom(8);
    this.map.flyTo( this.mapCenter );
    setInterval(() => {
      this.map.invalidateSize(true);

      if(this.corners.length >0){
        var cornersbounds = latLngBounds(this.corners);

        var southWest = cornersbounds.getSouthWest();
        var northEast = cornersbounds.getNorthEast();
        var newbounds = new LatLngBounds(southWest , northEast);
        this.map.fitBounds(newbounds);
      }
      
    }, 1000);

    map.addEventListener('layeradd', (e) => {
      this.corners.push(e.layer.getLatLng());
      if(this.corners.length >0){
        var cornersbounds = latLngBounds(this.corners);
        var southWest = cornersbounds.getSouthWest();
        var northEast = cornersbounds.getNorthEast();
        var newbounds = new LatLngBounds(southWest , northEast);
        this.map.fitBounds(newbounds);
      }
     
    });
        
  }
}