<h1 mat-dialog-title>Add Participant</h1>
<div mat-dialog-content>
    <div *ngIf="isRequestProcessing" class="center">
        <mat-spinner></mat-spinner>
        <div class="status">Processing...</div>
        <mat-error *ngIf="processingError">{{processingError}}</mat-error>
    </div>

    <div *ngIf="!isRequestProcessing">
        <div *ngIf="userForm">
            <form [formGroup]="userForm" (ngSubmit)="onUserSearch()" class="basic-form">
                <div *ngIf="!user" class="text">Please enter the email address to add him to this event.
                    <br/>The participant need to have an account on RallyGO and Team and Vehicle created already.
                </div>
                <div *ngIf="user" class="text">Fulfill the participant form </div>
                <div fxFlex.gt-sm="100" fxFlex="100">
                    <mat-form-field>
                        <input matInput formControlName="email" placeholder="Enter user email">
                    </mat-form-field>
                    <mat-error *ngIf="userSearchError">{{userSearchError}}</mat-error>
                </div>
            </form>
        </div>
        <div *ngIf="user">
            <form [formGroup]="form" (ngSubmit)="onSubmit()" class="basic-form">
                <mat-form-field>
                    <mat-label>Team</mat-label>
                    <mat-select placeholder="Team" formControlName="team">
                        <mat-option value="">Choose Team</mat-option>
                        <mat-option  *ngFor="let team of teams" value="{{team._id}}">{{team.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Vehicle</mat-label>
                    <mat-select placeholder="Vehicle" formControlName="vehicle">
                        <mat-option value="">Choose Vehicle</mat-option>
                        <mat-option  *ngFor="let veh of vehicles" value="{{veh._id}}">{{veh.mark}} {{veh.model}} {{veh.nickname}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Status</mat-label>
                    <mat-select placeholder="Status" formControlName="status">
                        <mat-option value="">Choose Participation status</mat-option>
                        <mat-option value="approved">Approved - can take part of event</mat-option>
                        <mat-option value="pending">Pending - waiting for approval</mat-option>
                        <mat-option value="rejected">Rejected - can't take part of event</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Ticket</mat-label>
                    <mat-select placeholder="Ticket" formControlName="ticket">
                        <mat-option value="">Choose Ticket</mat-option>
                        <mat-option *ngFor="let ticket of tickets" value="{{ticket._id}}">{{ticket.name}} {{ticket.price/100}} {{ticket.currency}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Start Number</mat-label>
                    <input matInput formControlName="number" placeholder="Start Number">
                </mat-form-field>
            </form>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <div *ngIf="processingError">
        <button mat-button (click)="onNoClick()" tabindex="1">Cancel</button>
    </div>

    <div class="left-buttons" *ngIf="!isRequestProcessing">
        <button mat-button color="primary" [disabled]="userForm.invalid" (click)="onUserSearch()" tabindex="2">Search</button>
        <button mat-button (click)="onNoClick()" tabindex="1">Cancel</button>
        <button *ngIf="user" mat-button color="warn" [disabled]="!user" (click)="onSubmit()" tabindex="2">Add</button>
    </div>
</div>
