import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.css']
})
export class NotificationDialogComponent implements OnInit {
  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<NotificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
      this.form = new FormGroup({
        text: new FormControl( '', [Validators.required])
      });
  }

  onSave() {
    if (this.form.invalid) {
      return false;
    }

    this.dialogRef.close( this.form.value.text );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
