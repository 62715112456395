<div mat-dialog-title class="row">
    <div class="column">
        <mat-card-title>Configure Fee Package</mat-card-title>
        <mat-card-subtitle>Provide details about that package and payment associated with it</mat-card-subtitle>
    </div>
</div>

<div mat-dialog-content>
  <form [formGroup]="form" (ngSubmit)="onSubmit()" class="basic-form" fxLayout="row">
    <div class="row"> 
      <div class="column column-right">
        <span class="section-title">Package</span>

        <div fxFlex.gt-sm="100" fxFlex="100">
          <mat-form-field>
            <input matInput formControlName="name" placeholder="Package Name">
          </mat-form-field>
        </div>

        <div fxFlex.gt-sm="100" fxFlex="100">
          <mat-form-field>
            <input matInput formControlName="amount" placeholder="Total amount" readonly="true">
          </mat-form-field>
        </div>

        <div fxFlex.gt-sm="100" fxFlex="100">
          <mat-form-field>
            <mat-select formControlName="currency" placeholder="Currency">
              <mat-option *ngFor="let c of currencies" value="{{c.cc}}">{{c.cc}} - {{c.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      <!--      <div fxFlex.gt-sm="100" fxFlex="100">
        <mat-form-field>
          <mat-select formControlName="provider" placeholder="Payment provider">
            <mat-option value="manual">Manual</mat-option>
            <mat-option value="imoje">iMoje ING</mat-option>
          </mat-select>
        </mat-form-field>
      </div>-->


        <div fxFlex.gt-sm="100" fxFlex="100">
          <mat-form-field>
            <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="5" cdkAutosizeMaxRows="10" formControlName="desc"
              placeholder="Description"></textarea>
          </mat-form-field>
        </div>
        <div fxFlex.gt-sm="100" fxFlex="100">
          <mat-form-field>
            <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="5" cdkAutosizeMaxRows="10" formControlName="info"
              placeholder="Additional informations"></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="column">
        <span class="section-title">Split Payment
          <button (click)="onPaymentAdd()" mat-button color="primary">Add+</button>
        </span>
        <div class="fee-section" formArrayName="payments">
            <mat-list class="fee-list">
                <mat-list-item class="fee-list-item" (click)="onPaymentView(item.value, ix)" *ngFor="let item of form.controls['payments']['controls']; let ix = index" [formGroupName]="ix">
                    <mat-icon matListIcon>payment</mat-icon>
                    <h3 matLine> {{item.value.name}} </h3>
                    <p matLine>
                        <span>Amount: {{item.value.amount}} {{form.value.currency}} </span>,
                        <span>type: {{item.value.type}} </span>
                    </p>
                    <p matLine>
                        <span> Title: {{item.value.title}} </span>
                    </p>
                    <p matLine>
                        <span>IBAN: {{item.value.ban}} </span>
                    </p>
                    <p matLine>
                        <span>{{item.value.desc}} </span>
                    </p>
                </mat-list-item>
            </mat-list>
        </div>
      </div>
    </div>
  </form>
</div>

<div mat-dialog-actions>
  <button *ngIf="data.name" mat-button color="warn" (click)="onRemove()" tabindex="-1">Delete</button>
  <button mat-button (click)="onNoClick()" tabindex="-1">Cancel</button>
  <button mat-button [disabled]="form.invalid" (click)="onSave()" tabindex="2">Save</button>
</div>