import { Component, OnInit, Input } from '@angular/core';
import { Map, latLng, tileLayer, Layer, marker, icon, Handler, Marker, LatLng} from 'leaflet';
import {MapService} from '../../../services/map.service';

@Component({
  selector: 'app-map-selector',
  templateUrl: './map-selector.component.html',
  styleUrls: ['./map-selector.component.css']
})
export class MapSelectorComponent implements OnInit {
  mapOptions: any;
  mapCenter: LatLng;
  loc: any;
  map: Map;

  constructor(
      private mapSvc: MapService
  ) {
  }

  @Input() onClick;

  @Input() set layer(value: Marker) {

    if (!value) {
      return;
    }

    this.marker = value;
  }

  @Input() zoom: number;
  @Input() marker: Marker;
  @Input() set center(val: LatLng) {
    if (!val) {
      return;
    }

    this.mapCenter = val;
    this.marker = new Marker(val);
    this.zoom = 15;
  }

  @Input() set address( val: string ) {
    console.log( val );
  }

  ngOnInit() {
    this.loc = {
      lat: 50.266769,
      lng: 19.025348
    };

    if (!navigator.geolocation) {
      console.log('Geolocation is not supported by your browser');
    } else {
      navigator.geolocation.getCurrentPosition( location => {
        this.loc = {
          lat: location.coords.latitude,
          lng: location.coords.longitude
        };

      }, err => {
        console.error( err );
        this.mapCenter = this.loc;
      });
    }

    if (!this.mapCenter) {
      this.mapCenter = this.loc;
    }

    this.mapOptions = {
      layers: [
        tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          maxZoom: 20,
          attribution: 'RallyGo',
        })
      ],
      zoom: this.zoom || 5,
      center: latLng(this.mapCenter.lat, this.mapCenter.lng)
    };
  }

  onMapClick( loc ) {
    console.log('Map Click', loc);

    if ( this.onClick ) {
      this.mapSvc.getAddressByGpsLocation( loc.latlng.lat, loc.latlng.lng ).subscribe( (address: any) => {
        console.log(address);
        address.lng = loc.latlng.lng;

        this.onClick( address );
      });
    }

    this.marker = new Marker( loc.latlng );
  }

  onMapReady( map: Map ) {
    map.invalidateSize(true);
    this.map = map;
    this.map.flyTo( this.mapCenter );

    setInterval(() => {
      this.map.invalidateSize(true);
    }, 1000);
  }
}
