import { NgModule } from '@angular/core';

import { MenuItems } from './menu-items/menu-items';
import { AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective } from './accordion';
import { EventSelectorComponent } from './event-selector/event-selector.component';
import { MaterialModule } from '../material-module';
import { EventSelectorDialogComponent } from './event-selector-dialog/event-selector-dialog.component';
import { CommonModule } from '@angular/common';
import { MomentModule } from 'ngx-moment';
import { ContactDialogComponent } from './contact-dialog/contact-dialog.component';
import { UserDialogComponent } from './user-dialog/user-dialog.component';
import { CampingDialogComponent } from './camping-dialog/camping-dialog.component';
import { CheckpointDialogComponent } from './checkpoint-dialog/checkpoint-dialog.component';
import { FeeDialogComponent } from './fee-dialog/fee-dialog.component';
import { TeamDialogComponent } from './team-dialog/team-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TeamMemberDialogComponent } from './team-member-dialog/team-member-dialog.component';
import { EventCreateDialogComponent } from './event-create-dialog/event-create-dialog.component';
import { RouteDialogComponent } from './route-dialog/route-dialog.component';
import { CampingSuggestDialogComponent } from './camping-suggest-dialog/camping-suggest-dialog.component';
import { MapComponent } from './map/map.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { ParticipantDialogComponent } from './participant-dialog/participant-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { NotificationDialogComponent } from './notification-dialog/notification-dialog.component';
import { PaymentDialogComponent } from './payment-dialog/payment-dialog.component';
import { FundraiseDialogComponent } from './fundraise-dialog/fundraise-dialog.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { PaymentDetailsDialogComponent } from './payment-details-dialog/payment-details-dialog.component';
import { MapSelectorComponent } from './map-selector/map-selector.component';
import { CampingDetailsDialogComponent } from './camping-details-dialog/camping-details-dialog.component';
import { FeePackageDialogComponent } from './fee-package-dialog/fee-package-dialog.component';
import { ParticipantEditDialogComponent } from './participant-edit-dialog/participant-edit-dialog.component';
import {TicketDialogComponent} from './ticket-dialog/ticket-dialog.component';
import { TransactionDetailsDialogComponent } from './transaction-details-dialog/transaction-details-dialog.component';
import {LoaderComponent} from './loader/loader.component';

@NgModule({
  imports: [
    MaterialModule,
    CommonModule,
    MomentModule,
    FormsModule,
    ReactiveFormsModule,
    LeafletModule,
  ],
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    EventSelectorComponent,
    EventSelectorDialogComponent,
    ContactDialogComponent,
    UserDialogComponent,
    CampingDialogComponent,
    CheckpointDialogComponent,
    FeeDialogComponent,
    TeamDialogComponent,
    TeamMemberDialogComponent,
    EventCreateDialogComponent,
    RouteDialogComponent,
    CampingSuggestDialogComponent,
    MapComponent,
    ParticipantDialogComponent,
    ConfirmDialogComponent,
    NotificationDialogComponent,
    PaymentDialogComponent,
    FundraiseDialogComponent,
    FileUploadComponent,
    PaymentDetailsDialogComponent,
    MapSelectorComponent,
    CampingDetailsDialogComponent,
    FeePackageDialogComponent,
    ParticipantEditDialogComponent,
      TicketDialogComponent,
      TransactionDetailsDialogComponent,
      LoaderComponent
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    EventSelectorComponent,
    EventSelectorDialogComponent,
    ContactDialogComponent,
    UserDialogComponent,
    CampingDialogComponent,
    CheckpointDialogComponent,
    FeeDialogComponent,
    TeamDialogComponent,
    MapComponent,
    FileUploadComponent,
    FeePackageDialogComponent,
    MapSelectorComponent,
    ParticipantEditDialogComponent,
    TicketDialogComponent,
    TransactionDetailsDialogComponent,
    LoaderComponent
  ],
  providers: [ MenuItems ]
})
export class SharedModule { }
