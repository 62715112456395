import { Friend } from './../models/friend';
import { Injectable } from '@angular/core';
import { Map, latLng, tileLayer, Layer, popup, marker, icon} from 'leaflet';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppService } from './app.service';
declare var ol: any;

@Injectable({
  providedIn: 'root'
})
export class MapService {

  addressAPI = {
    searchByLocation: 'https://nominatim.openstreetmap.org/reverse.php',
    searchByAddress: 'https://nominatim.openstreetmap.org/search'
  };

  constructor(
    private http: HttpClient,
    private app: AppService
    ) { }

  /**
   * @param lat string
   * @param lng string
   * @param _icon string
   * @return marker
   */
  createMarker( lat, lng, _icon= null ) {
    return marker([lat, lng], {
      icon: icon({
        iconSize: [ 25, 41 ],
        iconAnchor: [ 13, 41 ],
        iconUrl: _icon || 'leaflet/marker-icon.png',
        shadowUrl: 'leaflet/marker-shadow.png'
      })
    });
  }

  createCustomMarker( lat, lng, iconPath, size, anchor ) {
    return marker([lat, lng], {
      icon: icon({
        iconSize: size || [ 25, 41 ],
        iconAnchor: anchor || [ 13, 41 ],
        iconUrl: iconPath || 'leaflet/marker-icon.png',
        shadowUrl: 'leaflet/marker-shadow.png'
      })
    });
  }

  createPopup() {
    return popup();
  }

  getTeams(limit= 0, skip= 0, eventid= null, data= null) {
    const query: any = {
      limit: limit,
      skip: skip,
    };

    if (location) {
      query.distance = data.distance;
      query.location = data.location;
    }

    if ( eventid ) {
      query.event = eventid;
    }

    const params: HttpParams = new HttpParams({fromObject:  query });
    return this.http.get( this.app.api() + `/gps`, {params: params});
  }

  getFriends( friends: Friend[], limit= 0, skip= 0 ) {

    const query: any = {
      limit: limit,
      skip: skip,
      team: friends.map( friend =>  friend.team._id).join(',')
    };

    const params: HttpParams = new HttpParams({fromObject:  query });
    return this.http.get( this.app.api() + `/gps`, {params: params});
  }

  getAddressByGpsLocation(lat: string, lng: string) {
    const query = {
      format: 'jsonv2',
      lat: lat,
      lon: lng
    };
    const params: HttpParams = new HttpParams({fromObject:  query });

    return this.http.get( this.addressAPI.searchByLocation, {params: params} );
  }

  getGPSLocationByAddress(address: string) {
    const query = {
      format: 'jsonv2',
      q: address,
      polygon: '1',
      addressdetails: '1',
    };

    const params: HttpParams = new HttpParams({fromObject:  query });

    return this.http.get( this.addressAPI.searchByAddress, {params: params} );
  }
}
