import { Payment } from './../../../models/payment';
import { PaymentsService } from './../../../services/payments.service';
import { Component, OnInit, Inject } from '@angular/core';
import { AppService } from '../../../services/app.service';
import { EventsService } from '../../../services/events.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-payment-details-dialog',
  templateUrl: './payment-details-dialog.component.html',
  styleUrls: ['./payment-details-dialog.component.css']
})
export class PaymentDetailsDialogComponent implements OnInit {
  form: FormGroup;
  viewed:boolean;

  constructor(
    private app: AppService,
    private evSvc: EventsService,
    private paySvc: PaymentsService,
    public dialogRef: MatDialogRef<PaymentDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Payment) {

    }

  ngOnInit(): void {
    console.log('Transaxtion', this.data );
    this.form = new FormGroup({
      status: new FormControl( this.data.status, [Validators.required]),
      viewed: new FormControl( this.data.viewed),
      invoice: new FormControl( this.data.invoice),
      comment: new FormControl( this.data.comment),
    });
    this.viewed = false;
  }
  onViewStatusChanged(flter, event){
    this.viewed = event.target.checked
  }

  onAttachmentDownload(filename: string) {
    this.paySvc.attachment( this.data._id, this.data.event._id, filename ).subscribe( (data: any) => {
      const link = document.createElement('a');
      link.setAttribute('download', '');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', data.url);
      link.click();
      link.remove();
    }, err => {
      console.error( err );
    });
  }

  onSubmit() {
    this.data.status = this.form.value.status;
    this.data.invoice = this.form.value.invoice;
    this.data.viewed = this.form.value.viewed;
    this.data.comment = this.form.value.comment;

    let body = {
      "invoice":this.data.invoice,
      "status":this.data.status,
      "viewed":this.viewed,
      "comment":this.data.comment,
    }

    this.paySvc.changeStatus(  this.data._id, this.data.event._id, body ).subscribe( status => {
      this.dialogRef.close( this.data );
    }, err => {
      console.error( err );
    });
  }

}
