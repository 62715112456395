

<h1 mat-dialog-title>Campings

    <button class="btn_csd secondary-btn" [mat-dialog-close]="openSuggest" mat-button>
        Suggest
    </button>
</h1>

<div mat-dialog-content>
    <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Search..." />
    </mat-form-field>

    <div class="camps-section">
            <table>
                    <th class="td-name">Name</th>
                    <th class="td-name">City</th>
                    <th class="td-name">Country</th>
                    <th class="td-name">Area</th>


                <tr (click)="onSelect(camp)" *ngFor="let camp of campings; let ix = index" class="camp-item">
                    
                    <td>{{ camp.name }}</td>
                    <td>{{ camp.city }} </td>
                    <td>{{ camp.country }}</td>
                    <td>{{ camp.code }} {{ camp.street }} </td>
                </tr>


            </table>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()" tabindex="-1">Cancel</button>
    <button mat-button [disabled]="!selectedCamp" [mat-dialog-close]="selectedCamp" tabindex="2">
    Select
  </button>
</div>