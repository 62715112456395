import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource, MatSnackBar } from '@angular/material';
import { Event } from '../../../models/event';
import { EventsService } from '../../../services/events.service';
import { AuthService } from '../../../services/auth.service';
import { EventCreateDialogComponent } from '../event-create-dialog/event-create-dialog.component';

@Component({
  selector: 'app-event-selector-dialog',
  templateUrl: './event-selector-dialog.component.html',
  styleUrls: ['./event-selector-dialog.scss']
})
export class EventSelectorDialogComponent {
  selectedEvent: any;

  events: any[];
  displayedColumns: string[] = [
    'type', 'name', 'start', 'finish'
  ];
  dataSource: any;

  constructor(
    private auth: AuthService,
    private evSvc: EventsService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EventSelectorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    const query = {
      user: this.auth.getUser()._id
    };

    this.evSvc.get(query).subscribe( (events: any[]) => {
      console.log('User events', events );
      this.dataSource = new MatTableDataSource( events );
    }, err => {
      console.error( err );
    });
  }

  applyFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSelect( item ) {
    this.selectedEvent = item;
  }

  onEventCreate() {
    const dialogRef = this.dialog.open(EventCreateDialogComponent, {
      width: '650px',
    });

    dialogRef.afterClosed().subscribe( (name: string) => {
      if (!name) {
        return false;
      }

      const body = {
        name: name,
        lead: ' ',
        desc: ' ',
        status: 'draft',
        type: 'private'
      };

      this.evSvc.create( body ).subscribe( event => {

        this.dialogRef.close(event);

        this.snackBar.open('Event created', 'OK!', {
          duration: 2000,
        });
      }, err => {
        this.snackBar.open('Error while creating Event!!', 'Oh no :(', {
          duration: 2000,
        });
      });
    });
  }
}
