<h2 mat-dialog-title>Suggest Camping</h2>
<p>Camping is missing? Add new to the event directly!</p>
<div mat-dialog-content>
    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="basic-form row">
        <div fxLayout="row wrap" class="column form-table">
            <!-- column -->
            <div fxFlex.gt-sm="100" fxFlex="100">
                <mat-form-field>
                    <input matInput formControlName="name" placeholder="Name">
                </mat-form-field>
            </div>
            <div fxFlex.gt-sm="100" fxFlex="100">
                <mat-form-field>
                    <mat-select formControlName="country" placeholder="Country">
                        <mat-option *ngFor="let c of countries" value="{{c.abbreviation}}">{{c.country}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex.gt-sm="100" fxFlex="100">
                <mat-form-field>
                    <input matInput formControlName="city" placeholder="City">
                </mat-form-field>
            </div>
            <div fxFlex.gt-sm="100" fxFlex="100">
                <mat-form-field>
                    <input matInput formControlName="street" placeholder="Street">
                </mat-form-field>
            </div>
            <div fxFlex.gt-sm="100" fxFlex="100">
                <mat-form-field>
                    <input matInput formControlName="code" placeholder="Post Code">
                </mat-form-field>
            </div>
            <div fxFlex.gt-sm="100" fxFlex="100">
                <mat-form-field>
                    <input matInput formControlName="area" placeholder="Area/location/district/state">
                </mat-form-field>
            </div>
            <div fxFlex.gt-sm="45" fxFlex="40">
                <mat-form-field>
                    <input matInput formControlName="lat" placeholder="Latitude">
                </mat-form-field>
            </div>
            <div fxFlex.gt-sm="45" fxFlex="40">
                <mat-form-field>
                    <input matInput formControlName="lng" placeholder="Longitude">
                </mat-form-field>
            </div>
            <div fxFlex.gt-sm="100" fxFlex="100">
                <mat-form-field>
                    <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="5" cdkAutosizeMaxRows="10" formControlName="desc" placeholder="Additional infomations"></textarea>
                </mat-form-field>
            </div>
        </div>

        <div fxLayout="row" class="column">
            <app-map-selector [onClick]="onMapClick" [center]="mapMarker"></app-map-selector>
        </div>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onDialogClose()" tabindex="-1">Cancel</button>
    <button mat-button [disabled]="form.invalid" (click)="onSubmit()" tabindex="2">Save</button>
</div>