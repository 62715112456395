import { AppService } from './app.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VehiclesService {

  constructor(
    private http: HttpClient,
    private app: AppService) {

  }

  types() {
    return this.http.get( this.app.api() + `/vehicles/types`);
  }

  get(query) {
    const params: HttpParams = new HttpParams({fromObject: query});
    return this.http.get( this.app.api() + `/vehicles`, {params: params} );
  }

  getById( id: string ) {
    return this.http.get( this.app.api() + `/vehicles/${id}` );
  }

  create( body: Object ) {
    return this.http.post( this.app.api() + `/vehicles`, body);
  }

  update( id: string, body: Object) {
    return this.http.put( this.app.api() + `/vehicles/${id}`, body);
  }

  remove( id: string ) {
    return this.http.delete( this.app.api() + `/vehicles/${id}`);
  }
}
