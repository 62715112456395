import { AppService } from './app.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  constructor(
    private http: HttpClient,
    private app: AppService) {

  }

  attachment( payment: string, event: string, filename: string ) {
    const body = {
      filename: filename
    };

    return this.http.post( this.app.api() + `/payments/events/${event}/${payment}/attachment`, body);
  }

  changeStatus( payment: string, event: string, body: any) {
    return this.http.put( this.app.api() + `/payments/events/${event}/${payment}`, body);

  }
}
