export class Product {
    _id: string;
    event: string;
    name: string;
    slug: string;
    summary: string;
    price: number;
    currency: string;
    availability: {
        from: Date
        until: Date
    };
    quantity: number;
    isAvailable: boolean;
    stripeAccountId: string;
    category: 'product' | 'ticket' | 'start-number';
    tax_behavior: 'exclusive' | 'inclusive' | 'none';

    items: any[];
    createdAt: Date;
    updatedAt: Date;
}
