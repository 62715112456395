import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class CheckpointsService {

  constructor(
    private http: HttpClient,
    private app: AppService) {
  }

  get(id: string) {
    return this.http.get( this.app.api() + `/events/${id}/checkpoints`);

  }

  add(  id: string, body: any ) {
    //body.verified = false;    
    return this.http.post( this.app.api() + `/events/${id}/checkpoints`, body );
  }

  update( idevent: string, idcheckpoint: string, body: any ) {
    return this.http.put( this.app.api() + `/events/${idevent}/checkpoints/${idcheckpoint}
    `, body );
  }

  remove( idevent: string, idcheckpoint: string ) {
    return this.http.delete( this.app.api() + `/events/${idevent}/checkpoints/${idcheckpoint}
    `);
  }
}
