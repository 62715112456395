import { Component, OnInit, Inject } from '@angular/core';
import { AppService } from '../../../services/app.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { FeeDialogComponent } from '../fee-dialog/fee-dialog.component';
import { Fee } from '../../../models/fee';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { FeePackage } from '../../../models/fee_package';

@Component({
  selector: 'app-fee-package-dialog',
  templateUrl: './fee-package-dialog.component.html',
  styleUrls: ['./fee-package-dialog.component.css']
})
export class FeePackageDialogComponent implements OnInit {
  create: boolean;
  currencies: any;
  form: FormGroup;
  transactions: any[];
  feeAmount: number;

  constructor(
    private app: AppService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<FeeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FeePackage
  ) {}
  
  ngOnInit(): void {
    this.currencies = [];
    this.transactions = [];
    this.feeAmount = 0;

    this.app.currencies().subscribe( (data: any[]) => {
      this.currencies = data;
    }, err => {
      console.error(err);
    });

    if (!this.data) {
      this.data = new FeePackage();
      this.create = true;
    }

    this.form = new FormGroup({
      name: new FormControl( this.data.name, [Validators.required]),
      desc: new FormControl( this.data.desc, [Validators.required]),
      amount: new FormControl( (this.data.amount / 100).toFixed(2), [Validators.required] ),
      currency: new FormControl( this.data.currency, [Validators.required] ),
      info: new FormControl( this.data.info, [] ),
      payments: new FormArray([])
    });

    if ( this.data ) {
      this.data.payments.forEach( pay => {
        this.onFeeAdd( pay );
      })
    }
  }

  onPaymentAdd() {
    this.onPaymentView(null, null);
  }

  onPaymentView(fee: Fee, index: number): void {
    if (!fee) {
      fee = null;
    }

    const dialogRef = this.dialog.open(FeeDialogComponent, {
      width: '500px',
      data: fee,
      autoFocus: true,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe( data => {
      if(data && data.action ) {
        if (data.action == 'delete' ) {
          this.onFeeRemove( index );
          return;
        }

        if ( data.action == 'create' ) {
          this.onFeeAdd( data.fee );
        }

        if (data.action == 'update' ) {
          this.form.controls['payments']['controls'][index].setValue( data.fee );
          this.calcFeeAmount();
        }
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onRemove(): void {
    this.dialogRef.close({action: 'delete'});
  }

  onSave() {

    const data = this.form.value;
    data.amount = ~~(data.amount * 100);

    if (data.amount < 0 ) {
      data.amount = 100;
    }

    data.payments.forEach( (p, ix, arr) => {
      arr[ix].amount = ~~(arr[ix].amount*100);

      if (arr[ix].amount < 0) {
        arr[ix].amount = 100;
      }
    });

    if(this.create) {
      this.dialogRef.close({action: 'create', package: data});
    } else {
      this.dialogRef.close({action: 'update', package: data});
    }
  }

  onSubmit() {

  }

  onFeeAdd( fee: Fee ) {
    (<FormArray>this.form.get('payments')).push(
      new FormGroup({
        amount: new FormControl( (fee.amount/100).toFixed(2), [Validators.required] ),
        ban: new FormControl( fee.ban, [Validators.required] ),
        name: new FormControl( fee.name, [Validators.required] ),
        title: new FormControl( fee.title, [Validators.required] ),
        desc: new FormControl( fee.desc, [Validators.required] ),
        type: new FormControl( fee.type, [Validators.required] ),
      })
    );

    this.calcFeeAmount();
  }

  onFeeRemove( index: number ) {
    (<FormArray>this.form.get('payments')).removeAt(index);

    this.calcFeeAmount();

    if (this.form.value.payments.length == 0) {
      //this.enableFee = false;
      //this.feeAmount = 0;
    }
  }

  private calcFeeAmount() {
    this.feeAmount = this.form.value.payments.reduce((acc, f) => acc + parseFloat(f.amount), 0);
    (<FormControl>this.form.get('amount')).setValue( this.feeAmount.toFixed(2) );
  }

}
