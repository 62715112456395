import { User } from './user';
import {} from './event_gps';
import {Team} from './team';

export class Checkpoint {
    _id: string;
    event: string;
    name: string;
    desc: string;
    gps: {
        coordinates: [number, number],
        type: string
    };
    order: number;
    points: number;
    teams: Team[];
}
