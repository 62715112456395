import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class CampsService {

  constructor(
    private http: HttpClient,
    private app: AppService) {
  }

  get(query: any) {
    const params: HttpParams = new HttpParams({fromObject: query});
    return this.http.get( this.app.api() + `/campings`, {params: params});
  }

  getById( id: string ) {
    return this.http.get( this.app.api() + `/campings/${id}` );
  }

  add( body: any ) {
    body.verified = false;

    return this.http.post( this.app.api() + `/campings/`, body );
  }

  update( idcamp: string, body: any ) {
    return this.http.put( this.app.api() + `/campings/${idcamp}`, body );
  }
}
