import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EventsService } from './../../../services/events.service';
import { Event } from './../../../models/event';
import { Post } from './../../../models/post';
import { Issue } from './../../../models/issue';
import { AppService } from './../../../services/app.service';
import { PostsService } from './../../../services/posts.service';
import { IssuesService } from './../../../services/issues.service';
import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import {Payment} from '../../../models/payment';
import {PaymentDetailsDialogComponent} from '../payment-details-dialog/payment-details-dialog.component';
import {TransactionsService} from '../../../services/transactions.service';
import {Transaction} from '../../../models/transactions';
import {TransactionDetailsDialogComponent} from '../transaction-details-dialog/transaction-details-dialog.component';

@Component({
  selector: 'app-participant-dialog',
  templateUrl: './participant-dialog.component.html',
  styleUrls: ['./participant-dialog.component.css']
})
export class ParticipantDialogComponent implements OnInit {
  payments: any[];
  form: FormGroup;
  imageUrl: string;
  event: Event;
  latestpost: Post
  latestissue: Issue;
  totalAmountArr: {};
  transactions: Transaction[];

  displayedColumns: string[] = [
     'date', 'order', 'ticket', 'amount', 'status', 'type',  'internal_comment', 'viewed',
  ];

  constructor(
    private evSvc: EventsService,
    private appSvc: AppService,
    private psSvc: PostsService,
    private trxSvc: TransactionsService,
    private isSvc: IssuesService,
    public dialogRef: MatDialogRef<ParticipantDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      //this.latestpost = new Post()
      //this.latestissue = new Issue()
     }

  ngOnInit(): void {
    this.event = JSON.parse( localStorage.getItem('event') );
    this.form = new FormGroup({
      status: new FormControl( this.data.status, [Validators.required]),
      number: new FormControl( this.data.number || 0 )
    });

    this.imageUrl = this.appSvc.imagesUrl();
    this.getTransactions();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSave() {

    if (this.form.valid) {
      this.dialogRef.close( this.form.value );
    }
  }

  onTransactionView(transaction: Transaction) {
      const dialogRef = this.dialog.open(TransactionDetailsDialogComponent, {
        width: '650px',
        data: transaction
      });

      dialogRef.afterClosed().subscribe( (trx: Transaction) => {
        //payment = _payment;
      });
  }

  private async getTransactions() {
    const query = {
      participant: this.data._id
    }

    this.totalAmountArr = {};
    try {
      this.transactions = await this.trxSvc.get(this.event._id, query).toPromise<any>();
      for (const trx of this.transactions) {
        if(this.totalAmountArr[trx.metadata.currency] !== null){
          this.totalAmountArr[trx.metadata.currency] += trx.metadata.amount;
        }else{
          this.totalAmountArr[trx.metadata.currency] = 0;
          this.totalAmountArr[trx.metadata.currency] += trx.metadata.amount;
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  private getPayments() {
    this.evSvc.payments( this.data.event, this.data.team._id ).subscribe( (payments: any[]) => {
      this.payments = payments;
      this.totalAmountArr = {};
      for ( let ix = 0; ix < payments.length; ix++) {
        let p = payments[ix];
        if(this.totalAmountArr[p.currency] !== null){
          this.totalAmountArr[p.currency] += p.amount;
        }else{
          this.totalAmountArr[p.currency] = 0;
          this.totalAmountArr[p.currency] += p.amount;
        }
      }
    }, err => {
      console.error(err);
    });
  }
  private getPost() {
    let body = {
      limit:1,
      event: this.event._id,
      team:this.data.team._id
    }
    this.psSvc.get(body ).subscribe( (post: any[]) => {
      this.latestpost = post[0];
    }, err => {
      console.error(err);
    });
  }
  private getIssue() {
    let body = {
      limit:1,
      event: this.event._id,
      team:this.data.team._id
    }
    this.isSvc.get(body ).subscribe( (issue: any[]) => {
      console.log( issue );
      this.latestissue = issue[0];
    }, err => {
      console.error(err);
    });
  }

}
