import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  ViewChild,
  HostListener,
  Directive,
  OnInit,
  AfterViewInit
} from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuItems } from '../../../shared/menu-items/menu-items';
import { AuthService } from '../../../../services/auth.service';
import { User } from '../../../../models/user';
import {Event} from '../../../../models/event';
import {AppService} from '../../../../services/app.service';
import config from '../../../../config';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class AppSidebarComponent implements OnDestroy, OnInit {
  mobileQuery: MediaQueryList;
  user: User;
  event: Event;
  image: string;
  version: string

  private _mobileQueryListener: () => void;

  constructor(
    private auth: AuthService,
    changeDetectorRef: ChangeDetectorRef,
    private app: AppService,
    media: MediaMatcher,
    public menuItems: MenuItems
  ) {
    this.image = '/assets/images/background/profile-bg.jpg';
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.version = config.version
  }

  ngOnInit(): void {
    this.user = this.auth.getUser();

    if (localStorage.event) {
      this.event = JSON.parse( localStorage.getItem('event') );
      this.event.image = this.app.fixImage(this.event.image);
      this.image = this.app.fixImage(this.event.image);
      console.log(this.event);
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
}
