<h1 mat-dialog-title>Participant: {{ data.metadata.team }}</h1>

<form [formGroup]="form" (ngSubmit)="onSubmit()" class="basic-form">
    <div fxFlex.gt-sm="100" fxFlex="100" *ngIf="form">
        <mat-form-field appearance="outline">
            <mat-label>Invoice Number</mat-label>
            <input formControlName="invoice" matInput placeholder="Invoice Number">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Internal Comment</mat-label>
            <input formControlName="comment" matInput placeholder="Internal Comment">
        </mat-form-field>
        <mat-form-field>
            <mat-select formControlName="status" placeholder="Status">
                <mat-option value="unpaid">Unpaid</mat-option>
                <mat-option value="pending">Pending</mat-option>
                <mat-option value="paid">Paid</mat-option>
                <mat-option value="failed">Failed</mat-option>
                <mat-option value="rejected">Rejected</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-checkbox formControlName="viewed">Viewed</mat-checkbox>
    </div>
</form>

<mat-list>
    <mat-list-item><b>Transaction</b>: {{data._id}} </mat-list-item>
    <mat-list-item><b>Order</b>: {{data.metadata.order_number}} </mat-list-item>
    <mat-list-item><b>Amount</b>: {{data.metadata.amount / 100}} {{data.metadata.currency}} </mat-list-item>
    <mat-list-item><b>Status</b>: {{data.status}}</mat-list-item>
    <mat-list-item><b>Type</b>: {{data.type}}</mat-list-item>
    <mat-list-item><b>Date</b>: {{data.createdAt}}</mat-list-item>
    <mat-list-item><b>Comment</b>: {{data.metadata.comment}} </mat-list-item>
</mat-list>
<mat-list *ngIf="data.type === 'donate'">
    <h3>Donation details</h3>
    <mat-list-item><b>Anonymous</b>: {{data.metadata.isAnonymous == true ? 'Yes' : 'No'}} </mat-list-item>
    <mat-list-item><b>Donor Email</b>: {{data.metadata.customer_email}} </mat-list-item>
    <mat-list-item><b>Donor First Name</b>: {{data.metadata.customer_fname}} </mat-list-item>
    <mat-list-item><b>Donor Last Name</b>: {{data.metadata.customer_lname}} </mat-list-item>
</mat-list>

<div mat-dialog-actions>
    <div class="left-buttons">
        <button color="accent" *ngIf="data.metadata.files?.length > 0 " mat-button (click)="onAttachmentDownload( data.metadata.files[0] )" tabindex="1">Get Attachment</button>
        <button mat-button (click)="dialogRef.close()" tabindex="2">Cancel</button>
        <button mat-button color="primary" [disabled]="form.invalid" (click)="onSubmit()" tabindex="2">Save</button>
    </div>
</div>
