import { User } from './user';

export class Camping {
    _id: string;
    user: User;
    gps: {
        coordinates: [number, number],
        type: string
    };

    name: string;
    area: string;
    desc: string;

    lat: number;
    lng: number;

    city: string;
    street: string;
    country: string;
    code: string;

    createdAt: Date;
    updatedAt: Date;
}
