import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { EventsService } from './../../../services/events.service';

@Component({
  selector: 'app-event-create-dialog',
  templateUrl: './event-create-dialog.component.html',
  styleUrls: ['./event-create-dialog.component.css']
})
export class EventCreateDialogComponent implements OnInit {
  form: FormGroup;

  constructor(
    private evSvc: EventsService,
    public dialogRef: MatDialogRef<EventCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {

    this.form = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(5)])
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {

    if (this.form.invalid) {
      return false;
    }

    this.dialogRef.close( this.form.value.name );
  }
}
