import { environment } from './../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  hostname: string;
  protocol: string;
  port: string;
  aws: string;
  ext: string;
  url: string;
  imUrl: string;
  currentExchangeRates: any;

  public events: EventEmitter<any>;

  constructor(private http: HttpClient, private transSvc: TranslateService) {
    this.events = new EventEmitter();

    console.log('ENV', environment);
    this.hostname = environment.url.api;
    this.protocol = 'http';

    if (environment.production) {
      this.protocol = 'https';
    }

    if (environment.acc) {
      this.protocol = 'https';
    }

    if (environment.test) {
      this.protocol = 'https';
    }

    if (environment.dev) {
      this.protocol = 'https';
    }
  }

  api(version= 'v2') {
    console.log('API', environment.url.api);
    return `${this.protocol}://${environment.url.api}/api/${version}`;
  }

  urls() {
    return environment.url;
  }


  imagesUrl() {
    return `https://${environment.url.images}/`;
  }

  public fixImage(image) {
    if (String(image).indexOf('https://') === 0 ) {
      return image;
    }

    if (String(image).indexOf('events/') === 0 ) {
      return this.imagesUrl() + `/${image}.jpg`;
    }
  }

  translate(text: string, params: any = {}) {
    return this.transSvc.instant(text, params);
  }

  public getUploadImageUrl(body) {
    return this.http.post(this.api() + '/app/image-upload-url', body);
  }

  public uploadImage(url, body, headers) {
    return this.http.put(url, body, { headers: headers });
  }

  public async uploadImageToBucket(body, image: File) {
    const data = await this.getUploadImageUrl(body).toPromise();

    const headers = {
      'Content-Type': 'image/jpg',
      'Content-Length': image.size,
    };

    try {
      await this.uploadImage(data['url'], image, headers).toPromise();
      console.log('Image uploaded');
      return await data['image'];
    } catch (err) {
      console.error('Error uploading image', err);
      return false;
    }
  }

  public icons() {
    return this.http.get(this.api() + '/app/icons');
  }

  public currencies() {
    return this.http.get(`/assets/currencies.json`);
  }

  public getNBPexchangeRates() {
    return this.http.get(this.api() + '/app/exchange-rates/a');
  }

  public countries() {
    return this.http.get(`/assets/countries.json`);
  }

  ConvertToCSV(objArray, headerList) {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let delimiter = ';';
    let row = 'No.' + delimiter;

    for (const index in headerList) {
      row += headerList[index] + delimiter;
    }

    row = row.slice(0, -1);
    str += row + '\r\n';

    for (let i = 0; i < array.length; i++) {
      let line = i + 1 + '';

      for (const index in headerList) {
        const head = headerList[index];
        line += delimiter + array[i][head];
      }

      str += line + '\r\n';
    }

    return str;
  }

  downloadFile(data, columns, filename = 'data') {
    const csvData = this.ConvertToCSV(data, columns);
    //console.log(csvData);

    const blob = new Blob(['\ufeff' + csvData], {
      type: 'text/csv;charset=utf-8;',
    });
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser =
      navigator.userAgent.indexOf('Safari') !== -1 &&
      navigator.userAgent.indexOf('Chrome') === -1;

    if (isSafariBrowser) {
      // if Safari open in new window to save file with random filename.
      dwldLink.setAttribute('target', '_blank');
    }

    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename + '.csv');
    dwldLink.style.visibility = 'hidden';

    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
}
