import { AppService } from './app.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Product} from '../models/product';

@Injectable({
  providedIn: 'root'
})
export class TicketsService {

  constructor(
    private http: HttpClient,
    private app: AppService) {

  }

  get( event: string, query: any = {} ) {
    const params: HttpParams = new HttpParams({fromObject: query});
    return this.http.get( this.app.api('v2') + `/events/${event}/tickets`, {params: params});
  }

  create(event: string, body: Product) {
    return this.http.post( this.app.api('v2') + `/events/${event}/tickets`, body);
  }

  update(event: string, body: Product) {
    return this.http.put( this.app.api('v2') + `/events/${event}/tickets/${body._id}`, body);
  }

  delete(event: string, ticket: string) {
    return this.http.delete( this.app.api('v2') + `/events/${event}/tickets/${ticket}`);
  }
}
