<div mat-dialog-title>
    <span>Event Manage</span>
</div>
<div mat-dialog-content>
    <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Search...">
    </mat-form-field>

    <table cdk-table [dataSource]="dataSource" class="mat-elevation-z0">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element" class="td-name">
                <a [mat-dialog-close]="element">{{element.name}} </a>
            </td>
        </ng-container>
        <ng-container matColumnDef="start">
            <th mat-header-cell *matHeaderCellDef> Start </th>
            <td mat-cell *matCellDef="let element">
                {{element.start.date | date:'mediumDate'}}
            </td>
        </ng-container>
        <ng-container matColumnDef="finish">
            <th mat-header-cell *matHeaderCellDef> Finish </th>
            <td mat-cell *matCellDef="let element">
                {{element.finish.date | date:'mediumDate'}} </td>
        </ng-container>

        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef> Type </th>
            <td mat-cell *matCellDef="let element">
                <mat-icon *ngIf="element.type=='public'" [matTooltip]="element.type">public</mat-icon>
                <mat-icon *ngIf="element.type=='restricted'" [matTooltip]="element.type">lock</mat-icon>
                <mat-icon *ngIf="element.type=='private'" [matTooltip]="element.type">how_to_reg</mat-icon>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="row-bordered"></tr>
        <tr mat-row (click)="onSelect(row)" *matRowDef="let row; columns: displayedColumns;" class="tr-hover"></tr>
    </table>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()" tabindex="-1">Cancel</button>
    <button mat-button [disabled]="!selectedEvent" [mat-dialog-close]="selectedEvent" tabindex="2">Select</button>
    <button mat-button (click)="onEventCreate()">Create</button>
</div>