import { Team } from './../models/team';
import { Friend } from './../models/friend';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { AppService } from './app.service';
import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  usrEvents: EventEmitter<any>;
  userAssets: string[] = [];

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private app: AppService,
  ) {
    this.usrEvents = new EventEmitter<any>();
  }

  id() {
    try {
      return this.auth.getUser()._id;
    } catch( e ) {
      return false;
    }
  }

  assets() {
    return this.http.get( this.app.api() + '/users/assets' );
  }

  friends() {
    return this.http.get( this.app.api() + '/friends');
  }

  teams() {
    return this.http.get( this.app.api() + `/teams?user=${this.auth.getUser()._id}`);
  }

  vehicles() {
    return this.http.get( this.app.api() + `/vehicles?user=${this.auth.getUser()._id}`);
  }

  events() {
    return this.http.get( this.app.api() + `/events?user=${this.auth.getUser()._id}`);
  }

  orgs() {
    return this.http.get( this.app.api() + `/orgs?user=${this.auth.getUser()._id}`);
  }

  rallies( teams: string[] ) {
    return this.http.get( this.app.api() + `/events/participants?team=${teams.join(',')}`);
  }

  notifs( targets: any[] ) {
    return this.http.get( this.app.api() + `/notifs/?target=${targets.join(',')}`);
  }

  search(email: string) {
    const body = {
      email: email
    };

    return this.http.post( this.app.api() + `/users/search`, body);
  }

  updateProfile( body: any ) {
    return this.http.put( this.app.api() + '/users', body);
  }

  updatePassword( passwd: string  ) {
    const body = {
      passwd: passwd
    };

    return this.http.put( this.app.api() + '/users/password', body);
  }

  addFriend(team: Team) {
    const body = {
      team: team._id
    }

    return this.http.post( this.app.api() + '/friends', body);
  }

  removeFriend(friend: Friend) {
    return this.http.delete( this.app.api() + `/friends/${friend._id}`);
  }

  iamOwner(doc: any) {
    if (! this.auth.isLoggedIn) {
      return false;
    }

    if ( localStorage.getItem('_assets_id') ) {
      this.userAssets = JSON.parse(localStorage.getItem('_assets_id') );
    }

    /**
     * Lets check if "doc" have a "owner"
     * attribute, then if users assets are author/owner of
     * this document
     */
    if (this.userAssets.length > 0 ) {
      if ( doc.owner && doc.owner._id ) {
        if (this.userAssets.indexOf(doc.owner._id) >= 0 ) {
          return true;
        }
      }

      if ( doc.owner && !doc.owner._id ) {
        if (this.userAssets.indexOf(doc.owner) >= 0 ) {
          return true;
        }
      }

      if ( !doc.owner ) {
        if (this.userAssets.indexOf(doc) >= 0 ) {
          return true;
        }
      }
    }

    return false;
  }
}