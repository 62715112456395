import { UsersService } from './../../../services/users.service';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { User } from '../../../models/user';

@Component({
  selector: 'app-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.css']
})
export class UserDialogComponent implements OnInit {
  form: FormGroup;
  errorMessage: string;
  user: User;

  constructor(
    private usrSvc: UsersService,
    public dialogRef: MatDialogRef<UserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) { }


  ngOnInit(): void {
    this.form = new FormGroup({
      email: new FormControl( '', [Validators.required, Validators.email])
    });

    this.errorMessage = '';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSearch() {

    if (this.form.invalid) {
      return false;
    }

    this.usrSvc.search( this.form.value.email ).subscribe( (user: User) => {
      this.user = user;
    }, err => {
      this.user = null;
      this.errorMessage = 'User not found!';
    });
  }

  onSave() {
    if (this.form.valid) {
      this.dialogRef.close( this.user );
    }
  }
}