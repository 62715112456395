import {Participant} from './../../../models/participant';
import {EventsService} from './../../../services/events.service';
import {Payment} from './../../../models/payment';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {Component, OnInit, Inject} from '@angular/core';
import {AppService} from '../../../services/app.service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {Event} from '../../../models/event';
import * as uuid from 'uuid';

@Component({
    selector: 'app-payment-dialog',
    templateUrl: './payment-dialog.component.html',
    styleUrls: ['./payment-dialog.component.css']
})
export class PaymentDialogComponent implements OnInit {
    create: boolean = true;
    form: FormGroup;
    currencies: any[];
    event: Event;
    participants: Participant[];
    teams: any[];

    constructor(
        private app: AppService,
        private evSvc: EventsService,
        public dialogRef: MatDialogRef<PaymentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit(): void {
        this.event = JSON.parse(localStorage.getItem('event'));
        this.currencies = [];

        this.evSvc.participants(this.event._id, 'all', {limit: 0}).subscribe((participants: Participant[]) => {
            this.participants = participants
                .filter(p => p.team ? true : false)
                .sort((a, b) => a.team.name.toUpperCase() > b.team.name.toUpperCase() ? 1 : -1);

            this.teams = this.participants.map((p: Participant) => {
                return {
                    display: p.team.name,
                    value: p.team._id
                };
            });
        });

        this.app.currencies().subscribe((data: any[]) => {
            this.currencies = data;
        }, err => {
            console.error(err);
        });

        if (!this.data) {
            this.data = new Payment();
            this.create = true;
        }

        this.form = new FormGroup({
            team: new FormControl(this.data.team, [Validators.required]),
            status: new FormControl(this.data.status, [Validators.required]),
            amount: new FormControl(this.data.amount, [Validators.pattern('^[+-]?\\d+(\\.\\d+)?$'), Validators.required, Validators.min(0)]),
            currency: new FormControl(this.data.currency),
            type: new FormControl(this.data.type, [Validators.required]),
            comment: new FormControl(this.data.comment, [Validators.required]),
        });
    }

    onSubmit() {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onRemove(): void {
        this.dialogRef.close({action: 'delete'});
    }

    onSave() {
        const payment = this.form.value;
        payment.amount = payment.amount * 100;
        payment.payment = uuid.v4();
        payment.transaction = uuid.v4();
        payment.provider = 'manual';
        payment.method = 'other';
        payment.code = 'other';
        payment.order = `Event${payment.type.charAt(0).toUpperCase() + payment.type.substr(1)}#${this.event._id}`;

        this.dialogRef.close(payment);
    }
}
