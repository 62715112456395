import { Fundraise } from './../../../models/fundraise';
import { Component, OnInit, Inject } from '@angular/core';
import {FormGroup, FormControl, Validators, FormArray} from '@angular/forms';
import { AppService } from '../../../services/app.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Fee } from '../../../models/fee';
import {VehiclesService} from '../../../services/vehicles.service';

@Component({
  selector: 'app-fundrise-dialog',
  templateUrl: './fundraise-dialog.component.html',
  styleUrls: ['./fundraise-dialog.component.css']
})
export class FundraiseDialogComponent implements OnInit {
  form: FormGroup;
  currencies: any[];
  create: boolean = false;
  vehicles: string[];

  constructor(
    private app: AppService,
    private vehSvc: VehiclesService,
    public dialogRef: MatDialogRef<FundraiseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {

    this.vehicles = [];
    this.currencies = [];

    this.vehSvc.types().toPromise().then( (data: string[]) => {
      this.vehicles = data;
    });

    this.app.currencies().subscribe( (data: any[]) => {
      this.currencies = data;
    }, err => {
      console.error(err);
    });

    if (!this.data) {
      this.data = new Fundraise();
      this.create = true;
    }

    this.form = new FormGroup({
      name: new FormControl( this.data.name, [Validators.required]),
      currency: new FormControl( this.data.currency, [Validators.required]),
      stripeAccount: new FormControl( this.data.stripeAccount, [Validators.required]),
      desc: new FormControl( this.data.desc, [Validators.required]),
      amount: new FormControl( this.data.amount > 0 ? this.data.amount/100 : 0 ),
      collect: new FormArray([])
    });

    if (this.data?.collect) {
      for ( const item of this.data.collect ) {
        this.onAddCollectRule( item.vehicle, item.amount / 100 );
      }
    }
  }

  onAddCollectRule(vehicle: string = '', amount: number= 0) {
    (<FormArray>this.form.get('collect')).push(
        new FormGroup({
          vehicle: new FormControl( vehicle ),
          amount: new FormControl( amount )
        })
    );
  }

  onRemoveCollectRule( index: number ) {
    (<FormArray>this.form.get('collect')).removeAt(index);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onRemove(): void {
    this.dialogRef.close({action: 'delete'});
  }

  onSave() {
    if(this.create) {
      this.dialogRef.close({action: 'create', fundraise: this.form.value});
    } else {
      this.dialogRef.close({action: 'update', fundraise: this.form.value});
    }

  }

  onSubmit() {

  }
}
