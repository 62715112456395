import { AppService } from './app.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TransactionsService {

  constructor(
    private http: HttpClient,
    private app: AppService) {

  }

  get( event: string, query: any ) {
    const params: HttpParams = new HttpParams({fromObject: query});
    return this.http.get( this.app.api('v3') + `/transactions/event/${event}`, {params: params});
  }

  update( transaction: string, event: string, body: any ) {
    return this.http.put( this.app.api('v3') + `/transactions/event/${event}/${transaction}`, body);
  }
}
