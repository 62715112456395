import { Component, OnInit, Inject } from '@angular/core';
import { AppService } from '../../../services/app.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { FeeDialogComponent } from '../fee-dialog/fee-dialog.component';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import {Product} from '../../../models/product';
import {Event} from '../../../models/event';

@Component({
  selector: 'app-ticket-dialog',
  templateUrl: './ticket-dialog.component.html',
  styleUrls: ['./ticket-dialog.component.css']
})
export class TicketDialogComponent implements OnInit {
  create: boolean;
  currencies: any;
  form: FormGroup;
  ticket: Product;
  event: Event;

  taxes: any[];
  taxBehavior: string[];

  constructor(
    private app: AppService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<FeeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Product
  ) {
    this.ticket = data;
    this.taxes = [{
      code: 'txcd_10000000',
      name: 'Electronically Supplied Services'
    }, {
        code: 'txcd_90000001',
        name: 'Cash donations'
    }, {
        code: 'txcd_00000000',
        name: 'Nontaxable'
    }];

    this.taxBehavior = [
      'exclusive',
      'inclusive',
      'none'
    ];
  }

  ngOnInit(): void {
    this.currencies = [];
    this.event = JSON.parse( localStorage.getItem('event') );

    this.app.currencies().subscribe( (data: any[]) => {
      this.currencies = data;
    }, err => {
      console.error(err);
    });

    if (!this.data) {
      this.data = new Product();
      this.create = true;
    }

    this.form = new FormGroup({
      name: new FormControl( this.data.name, [Validators.required]),
      summary: new FormControl( this.data.summary, [Validators.required]),
      price: new FormControl( this.data.price >= 0 ? (this.data.price / 100).toFixed(2) : 0, [Validators.required] ),
      currency: new FormControl( this.data.currency, [Validators.required] ),
      quantity: new FormControl( this.data.quantity, [Validators.required, Validators.min(0)] ),
      stripeAccountId: new FormControl( this.data.stripeAccountId || this.event.stripeAccountId ),
      tax_behavior: new FormControl( this.data.tax_behavior || 'exclusive' ),
      items:  new FormArray([])
    });

    if (this.data.items) {
      this.data.items.forEach( item => {
        this.onProductItemAdd( item.name, item.amount / 100, item.tax_code);
      });
    }

    this.form.valueChanges.subscribe(value => {
      this.recalculatePrice();
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onRemove(): void {
    this.dialogRef.close({action: 'delete', ticket: this.ticket });
  }

  onProductItemRemove( index: number ) {
    (<FormArray>this.form.get('items')).removeAt(index);
    this.recalculatePrice();
  }

  onProductItemAdd(name: string = '', amount: number = 0, taxCode: string = 'txcd_10000000') {
    (<FormArray>this.form.get('items')).push(
        new FormGroup({
            name: new FormControl( name),
            amount: new FormControl( amount ),
            tax_code: new FormControl( taxCode )
        })
    );
  }

  recalculatePrice() {
    const items = this.form.get('items').value;
    if (items.length > 0) {
      const price = items.reduce( (acc, item) => {
        return acc + (parseFloat(item.amount) ? parseFloat(item.amount) : 0);
      }, 0);

      this.form.patchValue({ price: price }, { emitEvent: false });
    }
  }

  onSave() {

    const data = {...this.ticket, ...this.form.value};
    // tslint:disable-next-line:no-bitwise
    data.price = ~~(data.price * 100);

    if (data.price < 0 ) {
      data.price = 0;
    }

    data.items = data.items.map( item => {
        // tslint:disable-next-line:no-bitwise
        item.amount = ~~(item.amount * 100);
        return item;
    });

    if (data.items.length > 0) {
      data.price = data.items.reduce( (acc, item) => {
        return acc + item.amount;
      }, 0);
    }

    if (this.create) {
      this.dialogRef.close({action: 'create', ticket: data});
    } else {
      this.dialogRef.close({action: 'update', ticket: data});
    }
  }

  onSubmit() {

  }
}
