<h1 mat-dialog-title>Participant: {{ data.team.name }}</h1>

<form [formGroup]="form" (ngSubmit)="onSubmit()" class="basic-form">
    <div fxFlex.gt-sm="100" fxFlex="100">
        <mat-form-field appearance="outline">
            <mat-label>Invoice Number</mat-label>
            <input formControlName="invoice" matInput placeholder="Invoice Number">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Extra Comment</mat-label>
            <input formControlName="comment" matInput placeholder="Extra Comment">
        </mat-form-field>
        <mat-form-field>
            <mat-select formControlName="status" placeholder="Status">
                <mat-option value="pending">Pending</mat-option>
                <mat-option value="settled">Settled</mat-option>
            </mat-select>
        </mat-form-field>
        <input type="checkbox" formControlName="viewed" [checked] (change)="onViewStatusChanged('viewed', $event)" />Viewed
    </div>
</form>

<mat-list>
    <mat-list-item><b>Transaction</b>: {{data.transaction}} </mat-list-item>
    <mat-list-item><b>Order</b>: {{data.order}} </mat-list-item>
    <mat-list-item><b>Amount</b>: {{data.amount / 100}} {{data.currency}} </mat-list-item>
    <mat-list-item><b>Status</b>: {{data.status}}</mat-list-item>
    <mat-list-item><b>Type</b>: {{data.type}}</mat-list-item>
    <mat-list-item><b>Date</b>: {{data.createdAt}}</mat-list-item>
</mat-list>

<div mat-dialog-actions>
    <div class="left-buttons">
        <button color="accent" *ngIf="data.files.length > 0 " mat-button (click)="onAttachmentDownload( data.files[0] )" tabindex="1">Get Attachment</button>
        <button mat-button (click)="dialogRef.close()" tabindex="2">Cancel</button>
        <button mat-button color="primary" [disabled]="form.invalid" (click)="onSubmit()" tabindex="2">Save</button>
    </div>
</div>
