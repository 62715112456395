export const environment = {
  production: false,
  acc: false,
  test: false,
  dev: true,
  local: false,
  url: {
    api: 'api.dev.rallygo.eu',
    images: 'images.dev.rallygo.eu'
  }
};
