import { Injectable } from '@angular/core';

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
}

const MENUITEMS = [
  { state: 'dashboard', name: 'Dashboard', type: 'link', icon: 'av_timer' },
  { state: 'notifications', type: 'link', name: 'Notifications', icon: 'notification_important' },
  { state: 'details', type: 'link', name: 'Details', icon: 'details' },
  { state: 'tickets', type: 'link', name: 'Tickets', icon: 'receipt_long' },
  { state: 'route', type: 'link', name: 'Route', icon: 'place' },
  { state: 'registration', type: 'link', name: 'Registration', icon: 'assignment' },
  { state: 'camps', type: 'link', name: 'Camps', icon: 'terrain' },
  { state: 'checkpoints', type: 'link', name: 'Checkpoints', icon: 'beenhere' },
  // { state: 'payments', type: 'link', name: 'Payments', icon: 'payment' },
  { state: 'fundraise', type: 'link', name: 'Fundraise', icon: 'emoji_events' },
  { state: 'participants', type: 'link', name: 'Participants', icon: 'group' },
  { state: 'transactions', type: 'link', name: 'Transactions', icon: 'payment' },
  { state: 'users', type: 'link', name: 'Users', icon: 'security' }
];

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}
